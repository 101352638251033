import { Box, Theme, Typography } from '@mui/material';
import  Accordion  from '@mui/material/Accordion';
import  AccordionDetails  from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFormDisplay, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { CancerEvents, LocalRecurrence, RegionalRecurrence, DistantRecurrence, ContralateralBreastCancer, SecondPrimaryInvasiveCancer } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';



declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface ICancerEventsFormProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(0.5, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(2, 0)
    },
    container:{
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent'
    },

    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
  
}));



const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const localRecurrenceColumns: IGroupedSubformGridCell<LocalRecurrence>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'invasive',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="invasiveSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'invasiveSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.invasive ? `${row?.invasive} - ${row?.invasiveSpecify}` : undefined
    },
    {
        name: 'erStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.erStatus
    },
    {
        name: 'prStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.prStatus
    },
    {
        name: 'her2Status',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.her2Status
    },
    {
        name: 'suspiciousDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousDate
    },
    {
        name: 'definiteDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.definiteDate
    },
    {
        name: 'tumourBlockAvailable',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.tumourBlockAvailable
    }
];

const regionalRecurrenceColumns: IGroupedSubformGridCell<RegionalRecurrence>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'erStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.erStatus
    },
    {
        name: 'prStatus',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.prStatus
    },
    {
        name: 'her2Status',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.her2Status
    },
    {
        name: 'suspiciousDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousDate
    },
    {
        name: 'definiteDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.definiteDate
    }
];

const distantRecurrenceColumns: IGroupedSubformGridCell<DistantRecurrence>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'suspiciousForRecurrence',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousForRecurrence
    },
    {
        name: 'definiteRecurrence',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.definiteRecurrence
    }
];

const contralateralBreastCancerColumns: IGroupedSubformGridCell<ContralateralBreastCancer>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'suspiciousInvestigation',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousInvestigation
    },
    {
        name: 'suspiciousInvestigationDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.suspiciousInvestigationDate
    },
    {
        name: 'definiteDiagnosis',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="definiteDiagnosisSpecify"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        UpdatedFormPropertyStylerProps: {
            additionaProperties: [
                {
                    name: 'definiteDiagnosisSpecify'
                }
            ]
        },
        sortValue: (row) => !!row?.definiteDiagnosis ? `${row?.definiteDiagnosis} - ${row?.definiteDiagnosisSpecify}` : undefined
    },
    {
        name: 'definiteDiagnosisDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.definiteDiagnosisDate
    },
];

const secondPrimaryInvasiveColumns: IGroupedSubformGridCell<SecondPrimaryInvasiveCancer>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'diagnosisDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.diagnosisDate
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.site
    },
    {
        name: 'siteDescription',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.siteDescription
    }
];


const hasNoLocalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.localRecurrences || value?.localRecurrences?.length === 0;
const hasLocalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.localRecurrences && value?.localRecurrences?.length > 0;

const hasNoRegionalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.regionalRecurrences || value?.regionalRecurrences?.length === 0;
const hasRegionalRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.regionalRecurrences && value?.regionalRecurrences?.length > 0;

const hasNoDistantRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.distantRecurrences || value?.distantRecurrences?.length === 0;
const hasDistantRecurrence: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.distantRecurrences && value?.distantRecurrences?.length > 0;

const hasNoContralateralBreastCancer: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.contralateralBreastCancers || value?.contralateralBreastCancers?.length === 0;
const hasContralateralBreastCancer: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.contralateralBreastCancers && value?.contralateralBreastCancers?.length > 0;

const hasNoSecondPrimaryInvasive: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !value?.secondPrimaryInvasiveCancers || value?.secondPrimaryInvasiveCancers?.length === 0;
const hasSecondPrimaryInvasive: ICondition<CancerEvents> = ({ formState: { value } = {} }) => !!value?.secondPrimaryInvasiveCancers && value?.secondPrimaryInvasiveCancers?.length > 0;

const conditionSubscription = { value: true };

const CancerEventsForm: FunctionComponent<ICancerEventsFormProps> = () => {
    const { classes } = useStyles();



    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedField
                    name="bilateralBreastImagingDate"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="primaryTumourLaterality"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="primaryLocationOfTumour"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />


                <GroupedFields>
                    <Accordion disableGutters elevation={0} square>
                        <AccordionSummary
                            aria-controls="Local Reccurence Instruction"
                            id="LocalReccurence-header"
                            expandIcon={<FontAwesomeIcon fixedWidth icon={faChevronDown} />}
                        >
                        <Typography component="span" variant="h2" className={classes.subtitle}> Local Reccurence</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <strong>11.1 Local recurrence   </strong>
                            <p>Definition: Histologically confirmed invasive breast carcinoma or DCIS in any soft tissue of the ipsilateral conserved breast.  </p>
                            <p>Evidence</p>
                            <ul>
                                <li> Definite: Positive histology is essential.</li>
                                <li>Suspicious: Positive cytology; a visible or palpable lesion. Histological confirmation of diagnosis is essential.  </li>
                            </ul>
                            <p>Diagnosis of lobular carcinoma  in situ  in the ipsilateral conserved breast is not considered a LR. <br></br>  FFPE samples of the LR will be collected and stored (refer Section 17.2).  </p>
                           


                        </AccordionDetails>

                    </Accordion>
                        
                    <Condition
                        type={CancerEvents}
                        condition={hasLocalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.gridSize}>
                            <GroupedSubformGrid
                                
                                type={LocalRecurrence}
                                name="localRecurrences"
                                columns={localRecurrenceColumns}
                                disabled
                            />
                        </div>
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoLocalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Local Recurrence"
                                description="There is no local recurrence associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>

                    <Accordion disableGutters elevation={0} square>
                        <AccordionSummary

                            aria-controls="Regional Reccurence Instruction"
                            id="RegionalReccurence-header"
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}

                        >
                     

                            <Typography component="span" variant="h2" className={classes.subtitle}> Regional Reccurence</Typography>
                            
                        </AccordionSummary>

                        <AccordionDetails>
                            <strong>11.2 Regional recurrence</strong>
                            <p>Definition: Recurrence involving the ipsilateral axillary (including infraclavicular), interpectoral, supraclavicular or internal mammary lymph nodes.  </p>
                            <p>Evidence</p>
                            <ul>
                                <li>Definite: Positive histology or cytology is essential.</li>
                                <li>Suspicious: Visible (on clinical examination or imaging study) or palpable lesion</li>
                            </ul>

                        </AccordionDetails>

                    </Accordion>

                    <Condition
                        type={CancerEvents}
                        condition={hasRegionalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={RegionalRecurrence}
                            name="regionalRecurrences"
                            columns={regionalRecurrenceColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoRegionalRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Regional Recurrence"
                                description="There is no regional recurrence associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                

                <GroupedFields>
                    <Accordion disableGutters elevation={0} square>
                        <AccordionSummary
                           
                            aria-controls="Distant Reccurences Instructions"
                            id="DistantReccurence-header"
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}

                        >
                        <Typography component="span" variant="h2" className={classes.subtitle}> Distant Reccurence</Typography>
                        </AccordionSummary>
                                <AccordionDetails>
                           
                                <strong> 11.3 Distant recurrence </strong>
                                <p> Definition: Metastasis involving sites other than the breasts (ipsilateral or contralateral) or ipsilateral axillary (including infraclavicular), interpectoral, supraclavicular or internal mammary lymph nodes.   </p>

                                <p>Evidence <br></br> Bones </p>

                            <ul>
                                <li> Definite: Positive cytology or histology; positive bone scan, 18-F FDG PET scan, CT or MRI scan showing multiple lesions with evidence of progressive disease confirmed by two sequential scans, or without an obvious benign aetiology.  </li>
                                <li> Suspicious: Positive X-ray, CT or MRI scan with evidence of lytic, blastic or mixed lytic-blastic lesions; positive bone scan or PET scan showing one new lesion only.   </li>
                            </ul>

                            <p> Note</p>
                            <ul>
                                <li> In the absence of progressive disease of a solitary lesion on bone scan or PET scan, biopsy is recommended.  </li>
                                <li> Any positive bone scan or PET scan in joints or recent areas of trauma cannot be used as a criterion for distant recurrence.   </li>
                            </ul>

                            <p>Bone Marrow</p>
                            <ul>
                                <li> Definite: Positive cytology or histology.   </li>
                                <li> uspicious: Leukoerythroblastic blood picture. </li>
                            </ul>


                            <p>Lungs </p>
                            <ul>
                                <li> Definite: Positive cytology or histology; positive CT, MRI or PET scan showing new pulmonary lesion(s) with evidence of progressive disease confirmed by two sequential scans, or without an obvious benign aetiology.  </li>
                                <li> uspicious: Leukoerythroblastic blood picture. </li>
                            </ul>

                            <p> Note: Biopsy of a solitary pulmonary lesion present on X-ray, CT, MRI or PET scan is recommended if it is safe and clinically appropriate to do so.  </p>

                            <p>Pleura</p>
                            <ul>
                                <li> Definite: Positive cytology or histology.</li>
                                <li> Suspicious: New pleural effusion.  </li>
                            </ul>

                            <p>Peritoneum  </p>
                            <ul>
                                <li> Definite: Positive cytology or histology.  </li>
                                <li> Suspicious: New ascites. </li>
                            </ul>

                            <p>Central nervous system</p>
                            <ul>
                                <li>Definite: Positive cytology or histology; positive CT or MRI scan.  </li>                         
                            </ul>

                            <p>Distant lymph nodes  </p>
                            <ul>
                                <li>Definite: Positive cytology or histology; positive CT, MRI or PET scan showing new distant lymphadenopathy with evidence of progressive disease confirmed by two sequential scans, or without an obvious benign aetiology  </li>
                            </ul>
                          </AccordionDetails>
                    </Accordion>


                    <Condition
                        type={CancerEvents}
                        condition={hasDistantRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={DistantRecurrence}
                            name="distantRecurrences"
                            columns={distantRecurrenceColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoDistantRecurrence}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Distant Recurrence"
                                description="There is no distant recurrence associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Accordion disableGutters elevation={0} square>
                        <AccordionSummary

                            aria-controls="Contralateral Breast Cancer Instruction"
                            id="ContralateralBreastCancer-header"
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}

                           
                        >
                        <Typography component="span" variant="h2" className={classes.subtitle}> Contralateral Breast Cancer </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <strong>11.4 Contralateral breast cancer </strong>
                            <p>Contralateral breast cancer is defined as histologically confirmed invasive breast carcinoma or DCIS in the contralateral breast.  </p>
                            <p>Evidence  </p>
                            <ul>
                                <li> Definite: Positive histology.  </li>
                                <li>Suspicious: Positive cytology; a visible or palpable lesion; suspicious mammogram, ultrasound or MRI. </li>
                            </ul>
                            <p>Diagnosis of LCIS alone in the contralateral breast is not considered as an event for secondary endpoints.  </p>


                        </AccordionDetails>

                    </Accordion>
                        
                    <Condition
                        type={CancerEvents}
                        condition={hasContralateralBreastCancer}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ContralateralBreastCancer}
                            name="contralateralBreastCancers"
                            columns={contralateralBreastCancerColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoContralateralBreastCancer}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Contralateral Breast Cancer"
                                description="There is contralateral breast cancer associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Accordion disableGutters elevation={0} square>
                        <AccordionSummary

                            aria-controls="Second (non-Breast) Primary Invastive Cancer Instruction"
                            id="secondNonBreastPrimaryInvasiveCancer-header"
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}

                        >
                        <Typography component="span" variant="h2" className={classes.subtitle}> Second (non-breast) primary invasive cancer</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <strong>11.5 Second (non-breast) primary invasive cancer  </strong>
                            <p>A positive diagnosis of a second (non-breast) primary invasive cancer except basal or squamous cell carcinoma of the skin, cervical carcinoma  in situ  or bladder cancer  in situ, is to be reported as an event and as a serious adverse event (SAE) (refer Section 15.2). </p>
                            <p>Evidence  </p>
                            <ul>
                                <li> Definite: Positive histology.  </li>
                                <li>Suspicious: Positive cytology; a visible or palpable lesion; suspicious mammogram, ultrasound or MRI. </li>
                            </ul>
                            <p>Diagnosis of LCIS alone in the contralateral breast is not considered as an event for secondary endpoints.  </p>


                        </AccordionDetails>

                    </Accordion>

                    <Condition
                        type={CancerEvents}
                        condition={hasSecondPrimaryInvasive}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={SecondPrimaryInvasiveCancer}
                            name="secondPrimaryInvasiveCancers"
                            columns={secondPrimaryInvasiveColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={CancerEvents}
                        condition={hasNoSecondPrimaryInvasive}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Second (non-breast) Primary Invasive Cancer"
                                description="There is no second (non-breast) primary invasive cancer associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>
                
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default CancerEventsForm;
