/* Options:
Date: 2025-01-15 11:14:10
Version: 6.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44345

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export interface IUserGetSingle
{
    metadata: { [index: string]: Object; };
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum MedicalReviewStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
}

export class AdditionalToxicity implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public toxicityName: string;
    public toxicityGrade: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdditionalToxicity>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByMedicalReviewIdAndRepeat extends IGet
{
    medicalReviewId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class Annotation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Annotation>) { (Object as any).assign(this, init); }
}

export class BreastImaging implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public imagingType: string;
    public imagingDate?: string;
    public laterality: string;
    public result: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastImaging>) { (Object as any).assign(this, init); }
}

export class BreastRadiationTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public laterality: string;
    public startDate?: string;
    public endDate?: string;
    public continuing: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastRadiationTherapy>) { (Object as any).assign(this, init); }
}

export class BreastSurgery implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public typeOfBreastSurgery: string;
    public surgeryDate?: string;
    public laterality?: string;
    public indication?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastSurgery>) { (Object as any).assign(this, init); }
}

export class BreastSurgeryType implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public surgeryTypeDate?: string;
    public surgeryType: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastSurgeryType>) { (Object as any).assign(this, init); }
}

export class CardiovascularHistory implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public historyType: string;
    public historyDate: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CardiovascularHistory>) { (Object as any).assign(this, init); }
}

export class Comment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public date?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public crfName: string;
    public commentText: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Comment>) { (Object as any).assign(this, init); }
}

export class ContralateralBreastCancer implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public suspiciousInvestigation: string;
    public suspiciousInvestigationDate?: string;
    public definiteDiagnosis: string;
    public definiteDiagnosisSpecify: string;
    public definiteDiagnosisDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ContralateralBreastCancer>) { (Object as any).assign(this, init); }
}

export class CytotoxicChemotherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public agentName: string;
    public startDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CytotoxicChemotherapy>) { (Object as any).assign(this, init); }
}

export class DistantRecurrence implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public site: string;
    public suspiciousForRecurrence: string;
    public definiteRecurrence: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<DistantRecurrence>) { (Object as any).assign(this, init); }
}

export class EndocrineTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public treatmentName: string;
    public dailyDose: string;
    public startDate: string;
    public stopDate: string;
    public stopDateReason: string;
    public reasonDetail: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<EndocrineTherapy>) { (Object as any).assign(this, init); }
}

export class FollowUp implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public assessmentDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FollowUp>) { (Object as any).assign(this, init); }
}

export class Immunotherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public agentName: string;
    public startDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Immunotherapy>) { (Object as any).assign(this, init); }
}

export class LocalRecurrence implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public site: string;
    public invasive: string;
    public invasiveSpecify: string;
    public erStatus: string;
    public prStatus: string;
    public her2Status: string;
    public suspiciousDate?: string;
    public definiteDate?: string;
    public tumourBlockAvailable: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<LocalRecurrence>) { (Object as any).assign(this, init); }
}

export class LostToFollowUp implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public datePatientStatusAssessed?: string;
    public visit: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<LostToFollowUp>) { (Object as any).assign(this, init); }
}

export class NodalSurgeryType implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public event: string;
    public surgeryDate?: string;
    public surgeryType: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NodalSurgeryType>) { (Object as any).assign(this, init); }
}

export class NonBreastRadiationTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public anatomicalLocation: string;
    public startDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NonBreastRadiationTherapy>) { (Object as any).assign(this, init); }
}

export class OtherBreastCancerRelatedSurgery implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public surgerySite: string;
    public surgeryDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgery>) { (Object as any).assign(this, init); }
}

export class OtherTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public other: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OtherTherapy>) { (Object as any).assign(this, init); }
}

export class ProsignaAssayResult implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public blockId: string;
    public suitable: string;
    public successful: string;
    public assayCompletionDate?: string;
    public subType: string;
    public rorScore: string;
    public stratificationRandomisedAs: string;
    public stratificationCorrected: string;
    public stratificationComments: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProsignaAssayResult>) { (Object as any).assign(this, init); }
}

export class ProtocolDeviation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProtocolDeviation>) { (Object as any).assign(this, init); }
}

export class RadiationTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public notApplicableReason: string;
    public site: string;
    public laterality: string;
    public startDate?: string;
    public endDate?: string;
    public cumulativeDose: string;
    public fractions: string;
    public photon: string;
    public electron: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<RadiationTherapy>) { (Object as any).assign(this, init); }
}

export class RegionalRecurrence implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public site: string;
    public erStatus: string;
    public prStatus: string;
    public her2Status: string;
    public suspiciousDate?: string;
    public definiteDate?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<RegionalRecurrence>) { (Object as any).assign(this, init); }
}

export class SecondPrimaryInvasiveCancer implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public diagnosisDate?: string;
    public site: string;
    public siteDescription: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancer>) { (Object as any).assign(this, init); }
}

export class SeriousAdverseEvent implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public opmsSaeId?: number;
    public saeNumber?: number;
    public ctcaeClass: string;
    public ctcaeTerm: string;
    public ctcaeGrade: string;
    public onset?: string;
    public outcome: string;
    public outcomeDate?: string;
    public endocrineTherapy: string;
    public radiationTherapy: string;
    public medicalReviewerComments: string;
    public siteComments: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SeriousAdverseEvent>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public section: string;
    public type: string;
    public name: string;
    public path: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class StratificationFactor implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public stratFactor: string;
    public randomisedAs: string;
    public correctStrat: string;
    public comments: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<StratificationFactor>) { (Object as any).assign(this, init); }
}

export class TreatmentToxicity implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public aeClass: string;
    public aeTerm: string;
    public aeGrade: string;
    public breastRadiationTherapyRelated: string;
    public endocrineTherapyRelated: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TreatmentToxicity>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public medicalReviewId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    // @Ignore()
    public queryNumber?: number;

    public repeat?: number;
    public question: string;
    public response: string;
    public recipientId?: number;
    public status?: QueryStatus;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class AdjuvantIpsilateralBreastRadiationTherapy implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public dateOfLastSurgicalProcedure?: string;
    public timeFromLastBreastConservingSurgery: string;
    public randomisationDate?: string;
    public lateralityOfPrimaryTumour: string;
    public protocolAssignedRtVariations: string;
    public plannedTotalDoseVariations: string;
    public reasonForDosageChange: string;
    public reasonForNotStarting: string;
    // @Ignore()
    public radiationTherapies: RadiationTherapy[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapy>) { (Object as any).assign(this, init); }
}

export class BaselineMedicalHistory implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public myocardialInfarction: string;
    public myocardialInfarctionDiagnosis: string;
    public strokeIschaemicAttack: string;
    public strokeIschaemicAttackDiagnosis: string;
    public angina: string;
    public anginaDiagnosis: string;
    public thromboembolicEvent: string;
    public thromboembolicEventDiagnosis: string;
    public hypertension: string;
    public hypertensionDiagnosis: string;
    public hypercholesterolemia: string;
    public hypercholesterolemiaDiagnosis: string;
    // @Ignore()
    public cardiovascularHistories: CardiovascularHistory[];

    public connectiveTissueDisorder: string;
    public diabetes: string;
    public rheumatoidArthritis: string;
    public hasThePatientEvenBeenASmoker: string;
    public isThePatientACurrentSmoker: string;
    public averageNumberOfCigarettesPerDay?: number;
    public totalNumberOfYearsAsASmoker?: number;
    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BaselineMedicalHistory>) { (Object as any).assign(this, init); }
}

export class BaselineToxicity implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public baselineToxicitiesPresent?: boolean;
    public dermatitisRadiation: string;
    public breastPain: string;
    public chestWallPain: string;
    public skinInduration: string;
    public fibrosisDeepConnectiveTissue: string;
    public lymphoedemaBreast: string;
    public breastInfection: string;
    public pneumonitis: string;
    public pulmonaryFibrosis: string;
    public fatigue: string;
    public chestPainCardiac: string;
    public myocardialInfarction: string;
    public arthralgia: string;
    public myalgia: string;
    public osteoporosis: string;
    public fracture: string;
    public hotFlashes: string;
    public vaginalDryness: string;
    public thromboembolicEvent: string;
    public stroke: string;
    public transientIschaemicAttacks: string;
    // @Ignore()
    public additionalToxicities: AdditionalToxicity[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BaselineToxicity>) { (Object as any).assign(this, init); }
}

export class BreastImagingForm implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public bilateralBreastImagingDate?: string;
    public primaryTumourLaterality: string;
    // @Ignore()
    public breastImagings: BreastImaging[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastImagingForm>) { (Object as any).assign(this, init); }
}

export class CancerEvents implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public bilateralBreastImagingDate?: string;
    public primaryTumourLaterality: string;
    public primaryLocationOfTumour: string;
    // @Ignore()
    public localRecurrences: LocalRecurrence[];

    // @Ignore()
    public regionalRecurrences: RegionalRecurrence[];

    // @Ignore()
    public distantRecurrences: DistantRecurrence[];

    // @Ignore()
    public contralateralBreastCancers: ContralateralBreastCancer[];

    // @Ignore()
    public secondPrimaryInvasiveCancers: SecondPrimaryInvasiveCancer[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CancerEvents>) { (Object as any).assign(this, init); }
}

export class ClinicalAssessmentForm implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public physicalExamDate?: string;
    public ecogPerformanceStatus: string;
    public height?: number;
    public weight?: number;
    public ethnicity: string;
    public breastMriDate?: string;
    public menopausalStatus: string;
    public patientPregnant: string;
    public evidenceOfDistantMetastasis: string;
    public patientLifeExpectancy: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ClinicalAssessmentForm>) { (Object as any).assign(this, init); }
}

export class Death implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public dateOfDeath?: string;
    public causeOfDeath: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Death>) { (Object as any).assign(this, init); }
}

export class EndocrineTherapyForm implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public dateOfLastBcs?: string;
    public dateOfRandomisation?: string;
    public treatmentArm: string;
    public rtStopDate?: string;
    // @Ignore()
    public endocrineTherapies: EndocrineTherapy[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<EndocrineTherapyForm>) { (Object as any).assign(this, init); }
}

export class GeneralInformation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantIneligible?: boolean;
    public dateOfIneligibilityConfirmed?: string;
    public reviewer: string;
    public reasonsForIneligibility: string;
    public ineligibilityComments: string;
    public excludeFromAnalysis?: boolean;
    public dateExclusionConfirmed?: string;
    public statistician: string;
    public reasonsForExclusion: string;
    public exclusionComments: string;
    public withdrawn?: boolean;
    public withdrawalOfConsentDate?: string;
    public withdrawalOfConsentReason: string;
    // @Ignore()
    public lostToFollowUps: LostToFollowUp[];

    // @Ignore()
    public stratificationFactors: StratificationFactor[];

    // @Ignore()
    public protocolDeviations: ProtocolDeviation[];

    // @Ignore()
    public comments: Comment[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GeneralInformation>) { (Object as any).assign(this, init); }
}

export class NonProtocolTherapyForm implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    // @Ignore()
    public breastSurgeries: BreastSurgery[];

    // @Ignore()
    public otherBreastCancerRelatedSurgeries: OtherBreastCancerRelatedSurgery[];

    // @Ignore()
    public breastRadiationTherapies: BreastRadiationTherapy[];

    // @Ignore()
    public nonBreastRadiationTherapies: NonBreastRadiationTherapy[];

    // @Ignore()
    public cytotoxicChemotherapies: CytotoxicChemotherapy[];

    // @Ignore()
    public immunotherapies: Immunotherapy[];

    // @Ignore()
    public otherTherapies: OtherTherapy[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NonProtocolTherapyForm>) { (Object as any).assign(this, init); }
}

export class ProsignaAssayResultForm implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    // @Ignore()
    public prosignaAssayResults: ProsignaAssayResult[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProsignaAssayResultForm>) { (Object as any).assign(this, init); }
}

export class SurgeryPathology implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public laterality: string;
    public surgeryDate?: string;
    // @Ignore()
    public breastSurgeryTypes: BreastSurgeryType[];

    public whatWasTheMaximumMicroscopicSizeOfTheInvasiveCarcinoma?: number;
    public invasiveHistologicType: string;
    public histologicGrade: string;
    public invasiveInvasiveCarcinomaTypeHistologicType: string;
    public wasCarcinomaInSituPresentInTheIpsilateralBreast: string;
    public histologicTypeOfCarcinomaInSitu: string;
    public wasTheIpsilateralDCISOrMixedCarcinomaInSituMultifocalOrMulticentric: string;
    public wereAllOfTheMarginsClearForInvasiveCarcinomaAndAnyAssociatedDCIS: string;
    public whatWasTheFinalMinimumRadialSurgicalMarginsForInvasiveCarcinomaAndAnyAssociatedDCIS: string;
    public stratificationSurgicalMargin1: string;
    public stratificationSurgicalMargin2: string;
    public evidenceOfClinicalOrPathogenicT4Disease: string;
    public wasThereAnyLymphovascularInvasion: string;
    // @Ignore()
    public nodalSurgeryTypes: NodalSurgeryType[];

    public numberOfNodesRemoved: string;
    public finalPathologicLymphNodeStatusOfAxilla: string;
    public percentageOfTumourCellsStained: string;
    public intensityOfStaining: string;
    public resultDeterminedFrom: string;
    public percentageOfTumourCellsStained1: string;
    public intensityOfStaining1: string;
    public resultDeterminedFrom1: string;
    public heR2Immunohistochemistry: string;
    public heR2InSituHybridisationChemistry: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurgeryPathology>) { (Object as any).assign(this, init); }
}

export class TreatmentToxicityForm implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public bilateralBreastImagingDate?: string;
    public primaryTumourLaterality: string;
    public primaryLocationOfTumour: string;
    // @Ignore()
    public followUps: FollowUp[];

    // @Ignore()
    public treatmentToxicities: TreatmentToxicity[];

    // @Ignore()
    public seriousAdverseEvents: SeriousAdverseEvent[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TreatmentToxicityForm>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class MedicalReviewPatient implements IMedicalReviewPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public assignedMedicalReviewers: number[];

    // @Ignore()
    public medicalReviewers: number[];

    // @Ignore()
    public requiresAttention: number[];

    // @Ignore()
    public reviewStatus?: MedicalReviewStatus;

    public constructor(init?: Partial<MedicalReviewPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class MedicalReviewRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public reviewerIds: number[];
    public medicalReviewId?: number;
    public reviewerId?: number;
    public medicalReviewerIds: number[];
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<MedicalReviewRequestContext>) { (Object as any).assign(this, init); }
}

export enum MedicalReviewPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewMedicalReview = 7,
    ViewMedicalReviewForm = 8,
    ViewQuery = 9,
    CreateMedicalReview = 10,
    CompleteMedicalReview = 11,
    CancelMedicalReview = 12,
    CompleteMedicalReviewForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    ViewAllPatients = 17,
    CancelQuery = 18,
    UpdateMedicalReview = 19,
}

export class Reviewer implements IReviewer
{
    public id?: number;
    public onTrial?: boolean;
    public masterGroups: { [index: number]: boolean; };
    public coordinatingGroups: { [index: number]: boolean; };
    public institutions: { [index: number]: boolean; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Reviewer>) { (Object as any).assign(this, init); }
}

export class MedicalReview implements IMedicalReview
{
    public id?: number;
    public patientId?: number;
    public medicalReviewDefinitionId?: number;
    public status?: MedicalReviewStatus;
    public reviewerId?: number;
    // @Ignore()
    public reviewerIds: number[];

    public repeat?: number;
    public startDate?: string;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public formDefinitionIds: number[];
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewGetSingleByPatientIdAndRepeat extends IGet
{
    patientId?: number;
    repeat?: number;
}

export interface IMedicalReviewGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export class MedicalReviewCreationOptions
{
    public formDefinitionIds: number[];
    public includeUnchangedForms?: boolean;
    public compareFormsAcrossMedicalReviewDefinitions?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationOptions>) { (Object as any).assign(this, init); }
}

export class MedicalReviewUpdateOptions extends MedicalReviewCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<MedicalReviewUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IMedicalReviewPostUpdateReview extends IPost
{
    metadata: { [index: string]: Object; };
    medicalReviewId?: number;
    options: MedicalReviewUpdateOptions;
}

export interface IMedicalReviewPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export interface IMedicalReviewGetNextFormByIds extends IGet
{
    medicalReviewId?: number;
    currentFormDefinitionId?: number;
    currentFormRepeat?: number;
    metadata: { [index: string]: Object; };
    statuses: MedicalReviewStatus[];
}

export interface IQueryGetCollectionByMedicalReviewId extends IGet
{
    medicalReviewId?: number;
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export class MedicalReviewFormType extends FormType implements IMedicalReviewFormType
{
    public order?: number;

    public constructor(init?: Partial<MedicalReviewFormType>) { super(init); (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class MedicalReviewFormDefinition extends FormDefinition implements IMedicalReviewFormDefinition
{
    public order?: number;

    public constructor(init?: Partial<MedicalReviewFormDefinition>) { super(init); (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class MedicalReviewDefinition implements IMedicalReviewDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<MedicalReviewDefinition>) { (Object as any).assign(this, init); }
}

export class QueryRecipient implements IQueryRecipient
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<QueryRecipient>) { (Object as any).assign(this, init); }
}

export class NextMedicalReviewForm
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public formDefinitionName: string;
    public formTypeId?: number;
    public formTypeCode: string;
    public formTypeName: string;
    public formRepeat?: number;

    public constructor(init?: Partial<NextMedicalReviewForm>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewNextFormResponse
{
    nextForm: NextMedicalReviewForm;
}

export interface IMedicalReviewForm extends IForm
{
    medicalReviewId?: number;
    medicalReviewStatus?: MedicalReviewStatus;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IMasterGroup extends IDataModel, IHasCode
{
    name: string;
}

export interface IHasCode
{
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId, IHasCode
{
    name: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel, IHasCode
{
    name: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId, IHasCode
{
    name: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IMedicalReviewPatient extends IPatient
{
    treatmentArm: string;
    assignedMedicalReviewers: number[];
    medicalReviewers: number[];
    requiresAttention: number[];
    reviewStatus?: MedicalReviewStatus;
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IReviewer extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial?: boolean;
    masterGroups: { [index: number]: boolean; };
    coordinatingGroups: { [index: number]: boolean; };
    institutions: { [index: number]: boolean; };
}

export interface IMedicalReview extends IDataModel, IHasPatientId
{
    medicalReviewDefinitionId?: number;
    status?: MedicalReviewStatus;
    reviewerId?: number;
    repeat?: number;
    formDefinitionIds: number[];
    reviewerIds: number[];
    startDate?: string;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    medicalReviewId?: number;
    propertyName: string;
    parentQueryId?: number;
    queryNumber?: number;
    repeat?: number;
    question: string;
    response: string;
    recipientId?: number;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IMedicalReviewFormType extends IFormType
{
    order?: number;
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IMedicalReviewFormDefinition extends IFormDefinition
{
    order?: number;
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IMedicalReviewDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IQueryRecipient extends IConfigurationModel
{
    name: string;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class SendMedicalReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendMedicalReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: MedicalReviewPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

export class GetTpMrStatusByInstitutionIdResponse
{
    public data: { [index: string]: string; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTpMrStatusByInstitutionIdResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewFormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalToxicitySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdditionalToxicity;

    public constructor(init?: Partial<AdditionalToxicitySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalToxicityCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdditionalToxicity[];

    public constructor(init?: Partial<AdditionalToxicityCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalToxicityValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdditionalToxicityValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Annotation[];

    public constructor(init?: Partial<AnnotationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AnnotationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastImaging;

    public constructor(init?: Partial<BreastImagingSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastImaging[];

    public constructor(init?: Partial<BreastImagingCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastImagingValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastRadiationTherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastRadiationTherapy;

    public constructor(init?: Partial<BreastRadiationTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastRadiationTherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastRadiationTherapy[];

    public constructor(init?: Partial<BreastRadiationTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastRadiationTherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastRadiationTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastSurgerySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastSurgery;

    public constructor(init?: Partial<BreastSurgerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastSurgeryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastSurgery[];

    public constructor(init?: Partial<BreastSurgeryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastSurgeryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastSurgeryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastSurgeryTypeSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastSurgeryType;

    public constructor(init?: Partial<BreastSurgeryTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastSurgeryTypeCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastSurgeryType[];

    public constructor(init?: Partial<BreastSurgeryTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastSurgeryTypeValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastSurgeryTypeValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CardiovascularHistorySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CardiovascularHistory;

    public constructor(init?: Partial<CardiovascularHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CardiovascularHistoryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CardiovascularHistory[];

    public constructor(init?: Partial<CardiovascularHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CardiovascularHistoryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CardiovascularHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Comment[];

    public constructor(init?: Partial<CommentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CommentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ContralateralBreastCancerSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ContralateralBreastCancer;

    public constructor(init?: Partial<ContralateralBreastCancerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ContralateralBreastCancerCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ContralateralBreastCancer[];

    public constructor(init?: Partial<ContralateralBreastCancerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ContralateralBreastCancerValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ContralateralBreastCancerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CytotoxicChemotherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CytotoxicChemotherapy;

    public constructor(init?: Partial<CytotoxicChemotherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CytotoxicChemotherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CytotoxicChemotherapy[];

    public constructor(init?: Partial<CytotoxicChemotherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CytotoxicChemotherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CytotoxicChemotherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DistantRecurrenceSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DistantRecurrence;

    public constructor(init?: Partial<DistantRecurrenceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DistantRecurrenceCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: DistantRecurrence[];

    public constructor(init?: Partial<DistantRecurrenceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DistantRecurrenceValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DistantRecurrenceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndocrineTherapy[];

    public constructor(init?: Partial<EndocrineTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<EndocrineTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FollowUpSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FollowUpCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: FollowUp[];

    public constructor(init?: Partial<FollowUpCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FollowUpValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<FollowUpValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImmunotherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Immunotherapy;

    public constructor(init?: Partial<ImmunotherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImmunotherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Immunotherapy[];

    public constructor(init?: Partial<ImmunotherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImmunotherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ImmunotherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LocalRecurrenceSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LocalRecurrence;

    public constructor(init?: Partial<LocalRecurrenceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LocalRecurrenceCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LocalRecurrence[];

    public constructor(init?: Partial<LocalRecurrenceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LocalRecurrenceValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LocalRecurrenceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LostToFollowUpSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LostToFollowUp;

    public constructor(init?: Partial<LostToFollowUpSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LostToFollowUpCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: LostToFollowUp[];

    public constructor(init?: Partial<LostToFollowUpCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LostToFollowUpValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LostToFollowUpValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NodalSurgeryTypeSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NodalSurgeryType;

    public constructor(init?: Partial<NodalSurgeryTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NodalSurgeryTypeCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NodalSurgeryType[];

    public constructor(init?: Partial<NodalSurgeryTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NodalSurgeryTypeValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NodalSurgeryTypeValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonBreastRadiationTherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonBreastRadiationTherapy;

    public constructor(init?: Partial<NonBreastRadiationTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonBreastRadiationTherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonBreastRadiationTherapy[];

    public constructor(init?: Partial<NonBreastRadiationTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonBreastRadiationTherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NonBreastRadiationTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherBreastCancerRelatedSurgerySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OtherBreastCancerRelatedSurgery;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherBreastCancerRelatedSurgeryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OtherBreastCancerRelatedSurgery[];

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherBreastCancerRelatedSurgeryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherTherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OtherTherapy;

    public constructor(init?: Partial<OtherTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherTherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OtherTherapy[];

    public constructor(init?: Partial<OtherTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherTherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OtherTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProsignaAssayResult[];

    public constructor(init?: Partial<ProsignaAssayResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProsignaAssayResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProtocolDeviation[];

    public constructor(init?: Partial<ProtocolDeviationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProtocolDeviationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiationTherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: RadiationTherapy;

    public constructor(init?: Partial<RadiationTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiationTherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: RadiationTherapy[];

    public constructor(init?: Partial<RadiationTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RadiationTherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<RadiationTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegionalRecurrenceSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: RegionalRecurrence;

    public constructor(init?: Partial<RegionalRecurrenceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegionalRecurrenceCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: RegionalRecurrence[];

    public constructor(init?: Partial<RegionalRecurrenceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegionalRecurrenceValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<RegionalRecurrenceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryInvasiveCancerSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SecondPrimaryInvasiveCancer;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryInvasiveCancerCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SecondPrimaryInvasiveCancer[];

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SecondPrimaryInvasiveCancerValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent[];

    public constructor(init?: Partial<SeriousAdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SeriousAdverseEventValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SeriousAdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class StratificationFactorSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: StratificationFactor;

    public constructor(init?: Partial<StratificationFactorSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class StratificationFactorCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: StratificationFactor[];

    public constructor(init?: Partial<StratificationFactorCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class StratificationFactorValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<StratificationFactorValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentToxicitySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TreatmentToxicity;

    public constructor(init?: Partial<TreatmentToxicitySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentToxicityCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TreatmentToxicity[];

    public constructor(init?: Partial<TreatmentToxicityCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentToxicityValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TreatmentToxicityValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdjuvantIpsilateralBreastRadiationTherapy;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AdjuvantIpsilateralBreastRadiationTherapy[];

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineMedicalHistorySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BaselineMedicalHistory;

    public constructor(init?: Partial<BaselineMedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineMedicalHistoryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BaselineMedicalHistory[];

    public constructor(init?: Partial<BaselineMedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineMedicalHistoryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BaselineMedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineToxicitySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BaselineToxicity;

    public constructor(init?: Partial<BaselineToxicitySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineToxicityCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BaselineToxicity[];

    public constructor(init?: Partial<BaselineToxicityCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaselineToxicityValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BaselineToxicityValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastImagingForm;

    public constructor(init?: Partial<BreastImagingFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: BreastImagingForm[];

    public constructor(init?: Partial<BreastImagingFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<BreastImagingFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerEventsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CancerEvents;

    public constructor(init?: Partial<CancerEventsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerEventsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CancerEvents[];

    public constructor(init?: Partial<CancerEventsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerEventsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CancerEventsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ClinicalAssessmentForm;

    public constructor(init?: Partial<ClinicalAssessmentFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ClinicalAssessmentForm[];

    public constructor(init?: Partial<ClinicalAssessmentFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ClinicalAssessmentFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Death[];

    public constructor(init?: Partial<DeathCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DeathValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapyFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndocrineTherapyForm;

    public constructor(init?: Partial<EndocrineTherapyFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapyFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndocrineTherapyForm[];

    public constructor(init?: Partial<EndocrineTherapyFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndocrineTherapyFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<EndocrineTherapyFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralInformation[];

    public constructor(init?: Partial<GeneralInformationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GeneralInformationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonProtocolTherapyFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonProtocolTherapyForm;

    public constructor(init?: Partial<NonProtocolTherapyFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonProtocolTherapyFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonProtocolTherapyForm[];

    public constructor(init?: Partial<NonProtocolTherapyFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonProtocolTherapyFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NonProtocolTherapyFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProsignaAssayResultForm;

    public constructor(init?: Partial<ProsignaAssayResultFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProsignaAssayResultForm[];

    public constructor(init?: Partial<ProsignaAssayResultFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProsignaAssayResultFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryPathologySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurgeryPathology;

    public constructor(init?: Partial<SurgeryPathologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryPathologyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurgeryPathology[];

    public constructor(init?: Partial<SurgeryPathologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryPathologyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgeryPathologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentToxicityFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TreatmentToxicityForm;

    public constructor(init?: Partial<TreatmentToxicityFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentToxicityFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TreatmentToxicityForm[];

    public constructor(init?: Partial<TreatmentToxicityFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentToxicityFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TreatmentToxicityFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReviewPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition;

    public constructor(init?: Partial<MedicalReviewDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition[];

    public constructor(init?: Partial<MedicalReviewDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient;

    public constructor(init?: Partial<QueryRecipientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient[];

    public constructor(init?: Partial<QueryRecipientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer[];

    public constructor(init?: Partial<ReviewerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ReviewerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview[];

    public constructor(init?: Partial<MedicalReviewCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalReviewValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCreationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=3)
    public hasNoForms?: boolean;

    // @DataMember(Order=4)
    public hasNoChanges?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<MedicalReviewValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewNextFormResponse implements IMedicalReviewNextFormResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public nextForm: NextMedicalReviewForm;

    public constructor(init?: Partial<MedicalReviewNextFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

export class QueueAvailableMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailableMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueAvailableMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class QueueStartedAndCompletedMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueStartedAndCompletedMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueStartedAndCompletedMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class SendMedicalReviewsAvailableEmails implements IReturn<SendMedicalReviewEmailResponse>
{

    public constructor(init?: Partial<SendMedicalReviewsAvailableEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewsAvailableEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendStartedAndCompletedMedicalReviewsEmail implements IReturn<SendMedicalReviewEmailResponse>
{

    public constructor(init?: Partial<SendStartedAndCompletedMedicalReviewsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStartedAndCompletedMedicalReviewsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendMedicalReviewCompleteEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public medicalReviewId?: number;

    public constructor(init?: Partial<SendMedicalReviewCompleteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewCompleteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/medical-review/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/emails/reviewer-query-summary/{ReviewerId}")
export class SendReviewerQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendReviewerQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendReviewerQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class QueueReviewerQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueReviewerQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueReviewerQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/queries", "GET")
export class QueryReport
{
    public institutionId?: number;
    public patientId?: number;
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/get/tp-status/", "GET")
// @Route("/get/tp-status/{InstitutionId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetTpMrStatusByInstitutionId implements IReturn<GetTpMrStatusByInstitutionIdResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<GetTpMrStatusByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTpMrStatusByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTpMrStatusByInstitutionIdResponse(); }
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/medical-review/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserGetSingle'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserSingleResponse(); }
}

// @Route("/medical-review/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/medical-review/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

// @Route("/medical-review/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type - MedicalReviewFormType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/medical-review/form-type/collection", "GET")
// @Api(Description="Form Type - MedicalReviewFormType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeCollectionResponse(); }
}

// @Route("/medical-review/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type - MedicalReviewFormType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/medical-review/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition - MedicalReviewFormDefinition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/medical-review/form-definition/collection", "GET")
// @Api(Description="Form Definition - MedicalReviewFormDefinition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

// @Route("/medical-review/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition - MedicalReviewFormDefinition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/medical-review/form/additional-toxicity/single/id/{Id}", "GET")
// @Api(Description="Form - AdditionalToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalToxicityGetSingleById implements IReturn<AdditionalToxicitySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdditionalToxicityGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalToxicityGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalToxicitySingleResponse(); }
}

// @Route("/medical-review/form/additional-toxicity/collection", "GET")
// @Api(Description="Form - AdditionalToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdditionalToxicityCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdditionalToxicityCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdditionalToxicityCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdditionalToxicityCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalToxicityGetCollection implements IReturn<AdditionalToxicityCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdditionalToxicityGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalToxicityGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalToxicityCollectionResponse(); }
}

// @Route("/medical-review/form/additional-toxicity/create", "POST")
// @Api(Description="Form - AdditionalToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalToxicityPostCreate implements IReturn<AdditionalToxicitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdditionalToxicity;

    public constructor(init?: Partial<AdditionalToxicityPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalToxicityPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalToxicitySingleResponse(); }
}

// @Route("/medical-review/form/additional-toxicity/save", "POST")
// @Api(Description="Form - AdditionalToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalToxicityPostSave implements IReturn<AdditionalToxicitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdditionalToxicity;

    public constructor(init?: Partial<AdditionalToxicityPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalToxicityPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalToxicitySingleResponse(); }
}

// @Route("/medical-review/form/additional-toxicity/validate", "POST")
// @Api(Description="Form - AdditionalToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdditionalToxicityValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdditionalToxicityValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdditionalToxicityValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdditionalToxicityValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdditionalToxicityValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalToxicityPostValidate implements IReturn<AdditionalToxicityValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdditionalToxicity;

    public constructor(init?: Partial<AdditionalToxicityPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalToxicityPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalToxicityValidationResponse(); }
}

// @Route("/medical-review/form/additional-toxicity/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdditionalToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalToxicityGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdditionalToxicitySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdditionalToxicityGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalToxicityGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalToxicitySingleResponse(); }
}

// @Route("/medical-review/form/additional-toxicity/single/update", "POST")
// @Api(Description="Form - AdditionalToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdditionalToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalToxicityPostUpdateById implements IReturn<AdditionalToxicitySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdditionalToxicityPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalToxicityPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalToxicitySingleResponse(); }
}

// @Route("/medical-review/form/annotation/single/id/{Id}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleById implements IReturn<AnnotationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AnnotationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/collection", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetCollection implements IReturn<AnnotationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AnnotationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationCollectionResponse(); }
}

// @Route("/medical-review/form/annotation/create", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostCreate implements IReturn<AnnotationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/save", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostSave implements IReturn<AnnotationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/validate", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostValidate implements IReturn<AnnotationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationValidationResponse(); }
}

// @Route("/medical-review/form/annotation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleByMedicalReviewIdAndRepeat implements IReturn<AnnotationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AnnotationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/single/update", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostUpdateById implements IReturn<AnnotationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AnnotationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging/single/id/{Id}", "GET")
// @Api(Description="Form - BreastImaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingGetSingleById implements IReturn<BreastImagingSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastImagingGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging/collection", "GET")
// @Api(Description="Form - BreastImaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastImagingGetCollection implements IReturn<BreastImagingCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastImagingGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingCollectionResponse(); }
}

// @Route("/medical-review/form/breast-imaging/create", "POST")
// @Api(Description="Form - BreastImaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingPostCreate implements IReturn<BreastImagingSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastImaging;

    public constructor(init?: Partial<BreastImagingPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging/save", "POST")
// @Api(Description="Form - BreastImaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingPostSave implements IReturn<BreastImagingSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastImaging;

    public constructor(init?: Partial<BreastImagingPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging/validate", "POST")
// @Api(Description="Form - BreastImaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingValidationResponse)", StatusCode=500)
// @DataContract
export class BreastImagingPostValidate implements IReturn<BreastImagingValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastImaging;

    public constructor(init?: Partial<BreastImagingPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingValidationResponse(); }
}

// @Route("/medical-review/form/breast-imaging/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastImaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastImagingSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastImagingGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging/single/update", "POST")
// @Api(Description="Form - BreastImaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingPostUpdateById implements IReturn<BreastImagingSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastImagingPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingSingleResponse(); }
}

// @Route("/medical-review/form/breast-radiation-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - BreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class BreastRadiationTherapyGetSingleById implements IReturn<BreastRadiationTherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastRadiationTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastRadiationTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/breast-radiation-therapy/collection", "GET")
// @Api(Description="Form - BreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastRadiationTherapyGetCollection implements IReturn<BreastRadiationTherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastRadiationTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastRadiationTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastRadiationTherapyCollectionResponse(); }
}

// @Route("/medical-review/form/breast-radiation-therapy/create", "POST")
// @Api(Description="Form - BreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class BreastRadiationTherapyPostCreate implements IReturn<BreastRadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastRadiationTherapy;

    public constructor(init?: Partial<BreastRadiationTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastRadiationTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/breast-radiation-therapy/save", "POST")
// @Api(Description="Form - BreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class BreastRadiationTherapyPostSave implements IReturn<BreastRadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastRadiationTherapy;

    public constructor(init?: Partial<BreastRadiationTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastRadiationTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/breast-radiation-therapy/validate", "POST")
// @Api(Description="Form - BreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class BreastRadiationTherapyPostValidate implements IReturn<BreastRadiationTherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastRadiationTherapy;

    public constructor(init?: Partial<BreastRadiationTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastRadiationTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastRadiationTherapyValidationResponse(); }
}

// @Route("/medical-review/form/breast-radiation-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class BreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastRadiationTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/breast-radiation-therapy/single/update", "POST")
// @Api(Description="Form - BreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class BreastRadiationTherapyPostUpdateById implements IReturn<BreastRadiationTherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastRadiationTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastRadiationTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery/single/id/{Id}", "GET")
// @Api(Description="Form - BreastSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryGetSingleById implements IReturn<BreastSurgerySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastSurgeryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgerySingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery/collection", "GET")
// @Api(Description="Form - BreastSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryGetCollection implements IReturn<BreastSurgeryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastSurgeryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgeryCollectionResponse(); }
}

// @Route("/medical-review/form/breast-surgery/create", "POST")
// @Api(Description="Form - BreastSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryPostCreate implements IReturn<BreastSurgerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastSurgery;

    public constructor(init?: Partial<BreastSurgeryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgerySingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery/save", "POST")
// @Api(Description="Form - BreastSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryPostSave implements IReturn<BreastSurgerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastSurgery;

    public constructor(init?: Partial<BreastSurgeryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastSurgerySingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery/validate", "POST")
// @Api(Description="Form - BreastSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryPostValidate implements IReturn<BreastSurgeryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastSurgery;

    public constructor(init?: Partial<BreastSurgeryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastSurgeryValidationResponse(); }
}

// @Route("/medical-review/form/breast-surgery/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastSurgerySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastSurgeryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgerySingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery/single/update", "POST")
// @Api(Description="Form - BreastSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryPostUpdateById implements IReturn<BreastSurgerySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastSurgeryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastSurgerySingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery-type/single/id/{Id}", "GET")
// @Api(Description="Form - BreastSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryTypeGetSingleById implements IReturn<BreastSurgeryTypeSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastSurgeryTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery-type/collection", "GET")
// @Api(Description="Form - BreastSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryTypeGetCollection implements IReturn<BreastSurgeryTypeCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastSurgeryTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgeryTypeCollectionResponse(); }
}

// @Route("/medical-review/form/breast-surgery-type/create", "POST")
// @Api(Description="Form - BreastSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryTypePostCreate implements IReturn<BreastSurgeryTypeSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastSurgeryType;

    public constructor(init?: Partial<BreastSurgeryTypePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryTypePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery-type/save", "POST")
// @Api(Description="Form - BreastSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryTypePostSave implements IReturn<BreastSurgeryTypeSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastSurgeryType;

    public constructor(init?: Partial<BreastSurgeryTypePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryTypePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery-type/validate", "POST")
// @Api(Description="Form - BreastSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeValidationResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryTypePostValidate implements IReturn<BreastSurgeryTypeValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastSurgeryType;

    public constructor(init?: Partial<BreastSurgeryTypePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryTypePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastSurgeryTypeValidationResponse(); }
}

// @Route("/medical-review/form/breast-surgery-type/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryTypeGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastSurgeryTypeSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastSurgeryTypeGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryTypeGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/breast-surgery-type/single/update", "POST")
// @Api(Description="Form - BreastSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class BreastSurgeryTypePostUpdateById implements IReturn<BreastSurgeryTypeSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastSurgeryTypePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastSurgeryTypePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/cardiovascular-history/single/id/{Id}", "GET")
// @Api(Description="Form - CardiovascularHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=500)
// @DataContract
export class CardiovascularHistoryGetSingleById implements IReturn<CardiovascularHistorySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CardiovascularHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CardiovascularHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CardiovascularHistorySingleResponse(); }
}

// @Route("/medical-review/form/cardiovascular-history/collection", "GET")
// @Api(Description="Form - CardiovascularHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class CardiovascularHistoryGetCollection implements IReturn<CardiovascularHistoryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CardiovascularHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CardiovascularHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CardiovascularHistoryCollectionResponse(); }
}

// @Route("/medical-review/form/cardiovascular-history/create", "POST")
// @Api(Description="Form - CardiovascularHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=500)
// @DataContract
export class CardiovascularHistoryPostCreate implements IReturn<CardiovascularHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CardiovascularHistory;

    public constructor(init?: Partial<CardiovascularHistoryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CardiovascularHistoryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CardiovascularHistorySingleResponse(); }
}

// @Route("/medical-review/form/cardiovascular-history/save", "POST")
// @Api(Description="Form - CardiovascularHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=500)
// @DataContract
export class CardiovascularHistoryPostSave implements IReturn<CardiovascularHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CardiovascularHistory;

    public constructor(init?: Partial<CardiovascularHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CardiovascularHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CardiovascularHistorySingleResponse(); }
}

// @Route("/medical-review/form/cardiovascular-history/validate", "POST")
// @Api(Description="Form - CardiovascularHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CardiovascularHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class CardiovascularHistoryPostValidate implements IReturn<CardiovascularHistoryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CardiovascularHistory;

    public constructor(init?: Partial<CardiovascularHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CardiovascularHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CardiovascularHistoryValidationResponse(); }
}

// @Route("/medical-review/form/cardiovascular-history/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - CardiovascularHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=500)
// @DataContract
export class CardiovascularHistoryGetSingleByMedicalReviewIdAndRepeat implements IReturn<CardiovascularHistorySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CardiovascularHistoryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CardiovascularHistoryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CardiovascularHistorySingleResponse(); }
}

// @Route("/medical-review/form/cardiovascular-history/single/update", "POST")
// @Api(Description="Form - CardiovascularHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CardiovascularHistorySingleResponse)", StatusCode=500)
// @DataContract
export class CardiovascularHistoryPostUpdateById implements IReturn<CardiovascularHistorySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CardiovascularHistoryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CardiovascularHistoryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CardiovascularHistorySingleResponse(); }
}

// @Route("/medical-review/form/comment/single/id/{Id}", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentGetSingleById implements IReturn<CommentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CommentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/collection", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=500)
// @DataContract
export class CommentGetCollection implements IReturn<CommentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CommentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentCollectionResponse(); }
}

// @Route("/medical-review/form/comment/create", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostCreate implements IReturn<CommentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/save", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostSave implements IReturn<CommentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/validate", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=500)
// @DataContract
export class CommentPostValidate implements IReturn<CommentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentValidationResponse(); }
}

// @Route("/medical-review/form/comment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentGetSingleByMedicalReviewIdAndRepeat implements IReturn<CommentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CommentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/single/update", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostUpdateById implements IReturn<CommentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CommentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/contralateral-breast-cancer/single/id/{Id}", "GET")
// @Api(Description="Form - ContralateralBreastCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=500)
// @DataContract
export class ContralateralBreastCancerGetSingleById implements IReturn<ContralateralBreastCancerSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ContralateralBreastCancerGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContralateralBreastCancerGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContralateralBreastCancerSingleResponse(); }
}

// @Route("/medical-review/form/contralateral-breast-cancer/collection", "GET")
// @Api(Description="Form - ContralateralBreastCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerCollectionResponse)", StatusCode=500)
// @DataContract
export class ContralateralBreastCancerGetCollection implements IReturn<ContralateralBreastCancerCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ContralateralBreastCancerGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContralateralBreastCancerGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContralateralBreastCancerCollectionResponse(); }
}

// @Route("/medical-review/form/contralateral-breast-cancer/create", "POST")
// @Api(Description="Form - ContralateralBreastCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=500)
// @DataContract
export class ContralateralBreastCancerPostCreate implements IReturn<ContralateralBreastCancerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ContralateralBreastCancer;

    public constructor(init?: Partial<ContralateralBreastCancerPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContralateralBreastCancerPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContralateralBreastCancerSingleResponse(); }
}

// @Route("/medical-review/form/contralateral-breast-cancer/save", "POST")
// @Api(Description="Form - ContralateralBreastCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=500)
// @DataContract
export class ContralateralBreastCancerPostSave implements IReturn<ContralateralBreastCancerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ContralateralBreastCancer;

    public constructor(init?: Partial<ContralateralBreastCancerPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContralateralBreastCancerPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ContralateralBreastCancerSingleResponse(); }
}

// @Route("/medical-review/form/contralateral-breast-cancer/validate", "POST")
// @Api(Description="Form - ContralateralBreastCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerValidationResponse)", StatusCode=500)
// @DataContract
export class ContralateralBreastCancerPostValidate implements IReturn<ContralateralBreastCancerValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ContralateralBreastCancer;

    public constructor(init?: Partial<ContralateralBreastCancerPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContralateralBreastCancerPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ContralateralBreastCancerValidationResponse(); }
}

// @Route("/medical-review/form/contralateral-breast-cancer/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ContralateralBreastCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=500)
// @DataContract
export class ContralateralBreastCancerGetSingleByMedicalReviewIdAndRepeat implements IReturn<ContralateralBreastCancerSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ContralateralBreastCancerGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContralateralBreastCancerGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContralateralBreastCancerSingleResponse(); }
}

// @Route("/medical-review/form/contralateral-breast-cancer/single/update", "POST")
// @Api(Description="Form - ContralateralBreastCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ContralateralBreastCancerSingleResponse)", StatusCode=500)
// @DataContract
export class ContralateralBreastCancerPostUpdateById implements IReturn<ContralateralBreastCancerSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ContralateralBreastCancerPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContralateralBreastCancerPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ContralateralBreastCancerSingleResponse(); }
}

// @Route("/medical-review/form/cytotoxic-chemotherapy/single/id/{Id}", "GET")
// @Api(Description="Form - CytotoxicChemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class CytotoxicChemotherapyGetSingleById implements IReturn<CytotoxicChemotherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CytotoxicChemotherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CytotoxicChemotherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CytotoxicChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/cytotoxic-chemotherapy/collection", "GET")
// @Api(Description="Form - CytotoxicChemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class CytotoxicChemotherapyGetCollection implements IReturn<CytotoxicChemotherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CytotoxicChemotherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CytotoxicChemotherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CytotoxicChemotherapyCollectionResponse(); }
}

// @Route("/medical-review/form/cytotoxic-chemotherapy/create", "POST")
// @Api(Description="Form - CytotoxicChemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class CytotoxicChemotherapyPostCreate implements IReturn<CytotoxicChemotherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CytotoxicChemotherapy;

    public constructor(init?: Partial<CytotoxicChemotherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CytotoxicChemotherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CytotoxicChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/cytotoxic-chemotherapy/save", "POST")
// @Api(Description="Form - CytotoxicChemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class CytotoxicChemotherapyPostSave implements IReturn<CytotoxicChemotherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CytotoxicChemotherapy;

    public constructor(init?: Partial<CytotoxicChemotherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CytotoxicChemotherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CytotoxicChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/cytotoxic-chemotherapy/validate", "POST")
// @Api(Description="Form - CytotoxicChemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapyValidationResponse)", StatusCode=500)
// @DataContract
export class CytotoxicChemotherapyPostValidate implements IReturn<CytotoxicChemotherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CytotoxicChemotherapy;

    public constructor(init?: Partial<CytotoxicChemotherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CytotoxicChemotherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CytotoxicChemotherapyValidationResponse(); }
}

// @Route("/medical-review/form/cytotoxic-chemotherapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - CytotoxicChemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class CytotoxicChemotherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<CytotoxicChemotherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CytotoxicChemotherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CytotoxicChemotherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CytotoxicChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/cytotoxic-chemotherapy/single/update", "POST")
// @Api(Description="Form - CytotoxicChemotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CytotoxicChemotherapySingleResponse)", StatusCode=500)
// @DataContract
export class CytotoxicChemotherapyPostUpdateById implements IReturn<CytotoxicChemotherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CytotoxicChemotherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CytotoxicChemotherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CytotoxicChemotherapySingleResponse(); }
}

// @Route("/medical-review/form/distant-recurrence/single/id/{Id}", "GET")
// @Api(Description="Form - DistantRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class DistantRecurrenceGetSingleById implements IReturn<DistantRecurrenceSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DistantRecurrenceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistantRecurrenceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistantRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/distant-recurrence/collection", "GET")
// @Api(Description="Form - DistantRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DistantRecurrenceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DistantRecurrenceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DistantRecurrenceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DistantRecurrenceCollectionResponse)", StatusCode=500)
// @DataContract
export class DistantRecurrenceGetCollection implements IReturn<DistantRecurrenceCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DistantRecurrenceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistantRecurrenceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistantRecurrenceCollectionResponse(); }
}

// @Route("/medical-review/form/distant-recurrence/create", "POST")
// @Api(Description="Form - DistantRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class DistantRecurrencePostCreate implements IReturn<DistantRecurrenceSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DistantRecurrence;

    public constructor(init?: Partial<DistantRecurrencePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistantRecurrencePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistantRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/distant-recurrence/save", "POST")
// @Api(Description="Form - DistantRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class DistantRecurrencePostSave implements IReturn<DistantRecurrenceSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DistantRecurrence;

    public constructor(init?: Partial<DistantRecurrencePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistantRecurrencePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistantRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/distant-recurrence/validate", "POST")
// @Api(Description="Form - DistantRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DistantRecurrenceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DistantRecurrenceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DistantRecurrenceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DistantRecurrenceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DistantRecurrenceValidationResponse)", StatusCode=500)
// @DataContract
export class DistantRecurrencePostValidate implements IReturn<DistantRecurrenceValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: DistantRecurrence;

    public constructor(init?: Partial<DistantRecurrencePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistantRecurrencePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistantRecurrenceValidationResponse(); }
}

// @Route("/medical-review/form/distant-recurrence/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - DistantRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class DistantRecurrenceGetSingleByMedicalReviewIdAndRepeat implements IReturn<DistantRecurrenceSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DistantRecurrenceGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistantRecurrenceGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistantRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/distant-recurrence/single/update", "POST")
// @Api(Description="Form - DistantRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DistantRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class DistantRecurrencePostUpdateById implements IReturn<DistantRecurrenceSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DistantRecurrencePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistantRecurrencePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistantRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyGetSingleById implements IReturn<EndocrineTherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EndocrineTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/collection", "GET")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyGetCollection implements IReturn<EndocrineTherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<EndocrineTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapyCollectionResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/create", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostCreate implements IReturn<EndocrineTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/save", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostSave implements IReturn<EndocrineTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/validate", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostValidate implements IReturn<EndocrineTherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapy;

    public constructor(init?: Partial<EndocrineTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapyValidationResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<EndocrineTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<EndocrineTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy/single/update", "POST")
// @Api(Description="Form - EndocrineTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapySingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyPostUpdateById implements IReturn<EndocrineTherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<EndocrineTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapySingleResponse(); }
}

// @Route("/medical-review/form/follow-up/single/id/{Id}", "GET")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpGetSingleById implements IReturn<FollowUpSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FollowUpGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/collection", "GET")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpCollectionResponse)", StatusCode=500)
// @DataContract
export class FollowUpGetCollection implements IReturn<FollowUpCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FollowUpGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpCollectionResponse(); }
}

// @Route("/medical-review/form/follow-up/create", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostCreate implements IReturn<FollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/save", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostSave implements IReturn<FollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/validate", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpValidationResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostValidate implements IReturn<FollowUpValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FollowUp;

    public constructor(init?: Partial<FollowUpPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FollowUpValidationResponse(); }
}

// @Route("/medical-review/form/follow-up/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpGetSingleByMedicalReviewIdAndRepeat implements IReturn<FollowUpSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<FollowUpGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/follow-up/single/update", "POST")
// @Api(Description="Form - FollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class FollowUpPostUpdateById implements IReturn<FollowUpSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<FollowUpPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FollowUpPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FollowUpSingleResponse(); }
}

// @Route("/medical-review/form/immunotherapy/single/id/{Id}", "GET")
// @Api(Description="Form - Immunotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ImmunotherapyGetSingleById implements IReturn<ImmunotherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImmunotherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImmunotherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImmunotherapySingleResponse(); }
}

// @Route("/medical-review/form/immunotherapy/collection", "GET")
// @Api(Description="Form - Immunotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImmunotherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImmunotherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImmunotherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImmunotherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class ImmunotherapyGetCollection implements IReturn<ImmunotherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ImmunotherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImmunotherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImmunotherapyCollectionResponse(); }
}

// @Route("/medical-review/form/immunotherapy/create", "POST")
// @Api(Description="Form - Immunotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ImmunotherapyPostCreate implements IReturn<ImmunotherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Immunotherapy;

    public constructor(init?: Partial<ImmunotherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImmunotherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImmunotherapySingleResponse(); }
}

// @Route("/medical-review/form/immunotherapy/save", "POST")
// @Api(Description="Form - Immunotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ImmunotherapyPostSave implements IReturn<ImmunotherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Immunotherapy;

    public constructor(init?: Partial<ImmunotherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImmunotherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImmunotherapySingleResponse(); }
}

// @Route("/medical-review/form/immunotherapy/validate", "POST")
// @Api(Description="Form - Immunotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImmunotherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImmunotherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImmunotherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImmunotherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImmunotherapyValidationResponse)", StatusCode=500)
// @DataContract
export class ImmunotherapyPostValidate implements IReturn<ImmunotherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Immunotherapy;

    public constructor(init?: Partial<ImmunotherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImmunotherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImmunotherapyValidationResponse(); }
}

// @Route("/medical-review/form/immunotherapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Immunotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ImmunotherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<ImmunotherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ImmunotherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImmunotherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImmunotherapySingleResponse(); }
}

// @Route("/medical-review/form/immunotherapy/single/update", "POST")
// @Api(Description="Form - Immunotherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ImmunotherapySingleResponse)", StatusCode=500)
// @DataContract
export class ImmunotherapyPostUpdateById implements IReturn<ImmunotherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ImmunotherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImmunotherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImmunotherapySingleResponse(); }
}

// @Route("/medical-review/form/local-recurrence/single/id/{Id}", "GET")
// @Api(Description="Form - LocalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class LocalRecurrenceGetSingleById implements IReturn<LocalRecurrenceSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LocalRecurrenceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalRecurrenceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LocalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/local-recurrence/collection", "GET")
// @Api(Description="Form - LocalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LocalRecurrenceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LocalRecurrenceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LocalRecurrenceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LocalRecurrenceCollectionResponse)", StatusCode=500)
// @DataContract
export class LocalRecurrenceGetCollection implements IReturn<LocalRecurrenceCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LocalRecurrenceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalRecurrenceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LocalRecurrenceCollectionResponse(); }
}

// @Route("/medical-review/form/local-recurrence/create", "POST")
// @Api(Description="Form - LocalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class LocalRecurrencePostCreate implements IReturn<LocalRecurrenceSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LocalRecurrence;

    public constructor(init?: Partial<LocalRecurrencePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalRecurrencePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LocalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/local-recurrence/save", "POST")
// @Api(Description="Form - LocalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class LocalRecurrencePostSave implements IReturn<LocalRecurrenceSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LocalRecurrence;

    public constructor(init?: Partial<LocalRecurrencePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalRecurrencePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LocalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/local-recurrence/validate", "POST")
// @Api(Description="Form - LocalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LocalRecurrenceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LocalRecurrenceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LocalRecurrenceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LocalRecurrenceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LocalRecurrenceValidationResponse)", StatusCode=500)
// @DataContract
export class LocalRecurrencePostValidate implements IReturn<LocalRecurrenceValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LocalRecurrence;

    public constructor(init?: Partial<LocalRecurrencePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalRecurrencePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LocalRecurrenceValidationResponse(); }
}

// @Route("/medical-review/form/local-recurrence/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - LocalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class LocalRecurrenceGetSingleByMedicalReviewIdAndRepeat implements IReturn<LocalRecurrenceSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<LocalRecurrenceGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalRecurrenceGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LocalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/local-recurrence/single/update", "POST")
// @Api(Description="Form - LocalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LocalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class LocalRecurrencePostUpdateById implements IReturn<LocalRecurrenceSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<LocalRecurrencePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalRecurrencePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LocalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/lost-to-follow-up/single/id/{Id}", "GET")
// @Api(Description="Form - LostToFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class LostToFollowUpGetSingleById implements IReturn<LostToFollowUpSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LostToFollowUpGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LostToFollowUpGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LostToFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/lost-to-follow-up/collection", "GET")
// @Api(Description="Form - LostToFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LostToFollowUpCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LostToFollowUpCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LostToFollowUpCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LostToFollowUpCollectionResponse)", StatusCode=500)
// @DataContract
export class LostToFollowUpGetCollection implements IReturn<LostToFollowUpCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LostToFollowUpGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LostToFollowUpGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LostToFollowUpCollectionResponse(); }
}

// @Route("/medical-review/form/lost-to-follow-up/create", "POST")
// @Api(Description="Form - LostToFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class LostToFollowUpPostCreate implements IReturn<LostToFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LostToFollowUp;

    public constructor(init?: Partial<LostToFollowUpPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LostToFollowUpPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LostToFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/lost-to-follow-up/save", "POST")
// @Api(Description="Form - LostToFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class LostToFollowUpPostSave implements IReturn<LostToFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LostToFollowUp;

    public constructor(init?: Partial<LostToFollowUpPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LostToFollowUpPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LostToFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/lost-to-follow-up/validate", "POST")
// @Api(Description="Form - LostToFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LostToFollowUpValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LostToFollowUpValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LostToFollowUpValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LostToFollowUpValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LostToFollowUpValidationResponse)", StatusCode=500)
// @DataContract
export class LostToFollowUpPostValidate implements IReturn<LostToFollowUpValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: LostToFollowUp;

    public constructor(init?: Partial<LostToFollowUpPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LostToFollowUpPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LostToFollowUpValidationResponse(); }
}

// @Route("/medical-review/form/lost-to-follow-up/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - LostToFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class LostToFollowUpGetSingleByMedicalReviewIdAndRepeat implements IReturn<LostToFollowUpSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<LostToFollowUpGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LostToFollowUpGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LostToFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/lost-to-follow-up/single/update", "POST")
// @Api(Description="Form - LostToFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LostToFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class LostToFollowUpPostUpdateById implements IReturn<LostToFollowUpSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<LostToFollowUpPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LostToFollowUpPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LostToFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/nodal-surgery-type/single/id/{Id}", "GET")
// @Api(Description="Form - NodalSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class NodalSurgeryTypeGetSingleById implements IReturn<NodalSurgeryTypeSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NodalSurgeryTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NodalSurgeryTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NodalSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/nodal-surgery-type/collection", "GET")
// @Api(Description="Form - NodalSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class NodalSurgeryTypeGetCollection implements IReturn<NodalSurgeryTypeCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NodalSurgeryTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NodalSurgeryTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NodalSurgeryTypeCollectionResponse(); }
}

// @Route("/medical-review/form/nodal-surgery-type/create", "POST")
// @Api(Description="Form - NodalSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class NodalSurgeryTypePostCreate implements IReturn<NodalSurgeryTypeSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NodalSurgeryType;

    public constructor(init?: Partial<NodalSurgeryTypePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NodalSurgeryTypePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NodalSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/nodal-surgery-type/save", "POST")
// @Api(Description="Form - NodalSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class NodalSurgeryTypePostSave implements IReturn<NodalSurgeryTypeSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NodalSurgeryType;

    public constructor(init?: Partial<NodalSurgeryTypePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NodalSurgeryTypePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NodalSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/nodal-surgery-type/validate", "POST")
// @Api(Description="Form - NodalSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeValidationResponse)", StatusCode=500)
// @DataContract
export class NodalSurgeryTypePostValidate implements IReturn<NodalSurgeryTypeValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NodalSurgeryType;

    public constructor(init?: Partial<NodalSurgeryTypePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NodalSurgeryTypePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NodalSurgeryTypeValidationResponse(); }
}

// @Route("/medical-review/form/nodal-surgery-type/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NodalSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class NodalSurgeryTypeGetSingleByMedicalReviewIdAndRepeat implements IReturn<NodalSurgeryTypeSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NodalSurgeryTypeGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NodalSurgeryTypeGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NodalSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/nodal-surgery-type/single/update", "POST")
// @Api(Description="Form - NodalSurgeryType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NodalSurgeryTypeSingleResponse)", StatusCode=500)
// @DataContract
export class NodalSurgeryTypePostUpdateById implements IReturn<NodalSurgeryTypeSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NodalSurgeryTypePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NodalSurgeryTypePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NodalSurgeryTypeSingleResponse(); }
}

// @Route("/medical-review/form/non-breast-radiation-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - NonBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NonBreastRadiationTherapyGetSingleById implements IReturn<NonBreastRadiationTherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NonBreastRadiationTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonBreastRadiationTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/non-breast-radiation-therapy/collection", "GET")
// @Api(Description="Form - NonBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class NonBreastRadiationTherapyGetCollection implements IReturn<NonBreastRadiationTherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NonBreastRadiationTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonBreastRadiationTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonBreastRadiationTherapyCollectionResponse(); }
}

// @Route("/medical-review/form/non-breast-radiation-therapy/create", "POST")
// @Api(Description="Form - NonBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NonBreastRadiationTherapyPostCreate implements IReturn<NonBreastRadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonBreastRadiationTherapy;

    public constructor(init?: Partial<NonBreastRadiationTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonBreastRadiationTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/non-breast-radiation-therapy/save", "POST")
// @Api(Description="Form - NonBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NonBreastRadiationTherapyPostSave implements IReturn<NonBreastRadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonBreastRadiationTherapy;

    public constructor(init?: Partial<NonBreastRadiationTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonBreastRadiationTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/non-breast-radiation-therapy/validate", "POST")
// @Api(Description="Form - NonBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class NonBreastRadiationTherapyPostValidate implements IReturn<NonBreastRadiationTherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonBreastRadiationTherapy;

    public constructor(init?: Partial<NonBreastRadiationTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonBreastRadiationTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonBreastRadiationTherapyValidationResponse(); }
}

// @Route("/medical-review/form/non-breast-radiation-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NonBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NonBreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<NonBreastRadiationTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NonBreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonBreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/non-breast-radiation-therapy/single/update", "POST")
// @Api(Description="Form - NonBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class NonBreastRadiationTherapyPostUpdateById implements IReturn<NonBreastRadiationTherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NonBreastRadiationTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonBreastRadiationTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/other-breast-cancer-related-surgery/single/id/{Id}", "GET")
// @Api(Description="Form - OtherBreastCancerRelatedSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class OtherBreastCancerRelatedSurgeryGetSingleById implements IReturn<OtherBreastCancerRelatedSurgerySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherBreastCancerRelatedSurgeryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherBreastCancerRelatedSurgerySingleResponse(); }
}

// @Route("/medical-review/form/other-breast-cancer-related-surgery/collection", "GET")
// @Api(Description="Form - OtherBreastCancerRelatedSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherBreastCancerRelatedSurgeryGetCollection implements IReturn<OtherBreastCancerRelatedSurgeryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherBreastCancerRelatedSurgeryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherBreastCancerRelatedSurgeryCollectionResponse(); }
}

// @Route("/medical-review/form/other-breast-cancer-related-surgery/create", "POST")
// @Api(Description="Form - OtherBreastCancerRelatedSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class OtherBreastCancerRelatedSurgeryPostCreate implements IReturn<OtherBreastCancerRelatedSurgerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherBreastCancerRelatedSurgery;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherBreastCancerRelatedSurgeryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherBreastCancerRelatedSurgerySingleResponse(); }
}

// @Route("/medical-review/form/other-breast-cancer-related-surgery/save", "POST")
// @Api(Description="Form - OtherBreastCancerRelatedSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class OtherBreastCancerRelatedSurgeryPostSave implements IReturn<OtherBreastCancerRelatedSurgerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherBreastCancerRelatedSurgery;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherBreastCancerRelatedSurgeryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherBreastCancerRelatedSurgerySingleResponse(); }
}

// @Route("/medical-review/form/other-breast-cancer-related-surgery/validate", "POST")
// @Api(Description="Form - OtherBreastCancerRelatedSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class OtherBreastCancerRelatedSurgeryPostValidate implements IReturn<OtherBreastCancerRelatedSurgeryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherBreastCancerRelatedSurgery;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherBreastCancerRelatedSurgeryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherBreastCancerRelatedSurgeryValidationResponse(); }
}

// @Route("/medical-review/form/other-breast-cancer-related-surgery/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OtherBreastCancerRelatedSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class OtherBreastCancerRelatedSurgeryGetSingleByMedicalReviewIdAndRepeat implements IReturn<OtherBreastCancerRelatedSurgerySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherBreastCancerRelatedSurgeryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherBreastCancerRelatedSurgerySingleResponse(); }
}

// @Route("/medical-review/form/other-breast-cancer-related-surgery/single/update", "POST")
// @Api(Description="Form - OtherBreastCancerRelatedSurgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherBreastCancerRelatedSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class OtherBreastCancerRelatedSurgeryPostUpdateById implements IReturn<OtherBreastCancerRelatedSurgerySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OtherBreastCancerRelatedSurgeryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherBreastCancerRelatedSurgeryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherBreastCancerRelatedSurgerySingleResponse(); }
}

// @Route("/medical-review/form/other-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - OtherTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=500)
// @DataContract
export class OtherTherapyGetSingleById implements IReturn<OtherTherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTherapySingleResponse(); }
}

// @Route("/medical-review/form/other-therapy/collection", "GET")
// @Api(Description="Form - OtherTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherTherapyGetCollection implements IReturn<OtherTherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OtherTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTherapyCollectionResponse(); }
}

// @Route("/medical-review/form/other-therapy/create", "POST")
// @Api(Description="Form - OtherTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=500)
// @DataContract
export class OtherTherapyPostCreate implements IReturn<OtherTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherTherapy;

    public constructor(init?: Partial<OtherTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTherapySingleResponse(); }
}

// @Route("/medical-review/form/other-therapy/save", "POST")
// @Api(Description="Form - OtherTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=500)
// @DataContract
export class OtherTherapyPostSave implements IReturn<OtherTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherTherapy;

    public constructor(init?: Partial<OtherTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherTherapySingleResponse(); }
}

// @Route("/medical-review/form/other-therapy/validate", "POST")
// @Api(Description="Form - OtherTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class OtherTherapyPostValidate implements IReturn<OtherTherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OtherTherapy;

    public constructor(init?: Partial<OtherTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherTherapyValidationResponse(); }
}

// @Route("/medical-review/form/other-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OtherTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=500)
// @DataContract
export class OtherTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<OtherTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OtherTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherTherapySingleResponse(); }
}

// @Route("/medical-review/form/other-therapy/single/update", "POST")
// @Api(Description="Form - OtherTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OtherTherapySingleResponse)", StatusCode=500)
// @DataContract
export class OtherTherapyPostUpdateById implements IReturn<OtherTherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OtherTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherTherapySingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result/single/id/{Id}", "GET")
// @Api(Description="Form - ProsignaAssayResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetSingleById implements IReturn<ProsignaAssayResultSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProsignaAssayResultGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result/collection", "GET")
// @Api(Description="Form - ProsignaAssayResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetCollection implements IReturn<ProsignaAssayResultCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProsignaAssayResultGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultCollectionResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result/create", "POST")
// @Api(Description="Form - ProsignaAssayResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostCreate implements IReturn<ProsignaAssayResultSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result/save", "POST")
// @Api(Description="Form - ProsignaAssayResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostSave implements IReturn<ProsignaAssayResultSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result/validate", "POST")
// @Api(Description="Form - ProsignaAssayResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostValidate implements IReturn<ProsignaAssayResultValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultValidationResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ProsignaAssayResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProsignaAssayResultSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result/single/update", "POST")
// @Api(Description="Form - ProsignaAssayResult")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostUpdateById implements IReturn<ProsignaAssayResultSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProsignaAssayResultPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/id/{Id}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleById implements IReturn<ProtocolDeviationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/collection", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetCollection implements IReturn<ProtocolDeviationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProtocolDeviationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationCollectionResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/create", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostCreate implements IReturn<ProtocolDeviationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/save", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostSave implements IReturn<ProtocolDeviationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/validate", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostValidate implements IReturn<ProtocolDeviationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationValidationResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProtocolDeviationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/update", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostUpdateById implements IReturn<ProtocolDeviationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProtocolDeviationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/radiation-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - RadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class RadiationTherapyGetSingleById implements IReturn<RadiationTherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<RadiationTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/radiation-therapy/collection", "GET")
// @Api(Description="Form - RadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class RadiationTherapyGetCollection implements IReturn<RadiationTherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<RadiationTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationTherapyCollectionResponse(); }
}

// @Route("/medical-review/form/radiation-therapy/create", "POST")
// @Api(Description="Form - RadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class RadiationTherapyPostCreate implements IReturn<RadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: RadiationTherapy;

    public constructor(init?: Partial<RadiationTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/radiation-therapy/save", "POST")
// @Api(Description="Form - RadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class RadiationTherapyPostSave implements IReturn<RadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: RadiationTherapy;

    public constructor(init?: Partial<RadiationTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/radiation-therapy/validate", "POST")
// @Api(Description="Form - RadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class RadiationTherapyPostValidate implements IReturn<RadiationTherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: RadiationTherapy;

    public constructor(init?: Partial<RadiationTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RadiationTherapyValidationResponse(); }
}

// @Route("/medical-review/form/radiation-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - RadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class RadiationTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<RadiationTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<RadiationTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/radiation-therapy/single/update", "POST")
// @Api(Description="Form - RadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class RadiationTherapyPostUpdateById implements IReturn<RadiationTherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<RadiationTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RadiationTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/regional-recurrence/single/id/{Id}", "GET")
// @Api(Description="Form - RegionalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class RegionalRecurrenceGetSingleById implements IReturn<RegionalRecurrenceSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<RegionalRecurrenceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegionalRecurrenceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RegionalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/regional-recurrence/collection", "GET")
// @Api(Description="Form - RegionalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceCollectionResponse)", StatusCode=500)
// @DataContract
export class RegionalRecurrenceGetCollection implements IReturn<RegionalRecurrenceCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<RegionalRecurrenceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegionalRecurrenceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RegionalRecurrenceCollectionResponse(); }
}

// @Route("/medical-review/form/regional-recurrence/create", "POST")
// @Api(Description="Form - RegionalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class RegionalRecurrencePostCreate implements IReturn<RegionalRecurrenceSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: RegionalRecurrence;

    public constructor(init?: Partial<RegionalRecurrencePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegionalRecurrencePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RegionalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/regional-recurrence/save", "POST")
// @Api(Description="Form - RegionalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class RegionalRecurrencePostSave implements IReturn<RegionalRecurrenceSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: RegionalRecurrence;

    public constructor(init?: Partial<RegionalRecurrencePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegionalRecurrencePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegionalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/regional-recurrence/validate", "POST")
// @Api(Description="Form - RegionalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceValidationResponse)", StatusCode=500)
// @DataContract
export class RegionalRecurrencePostValidate implements IReturn<RegionalRecurrenceValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: RegionalRecurrence;

    public constructor(init?: Partial<RegionalRecurrencePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegionalRecurrencePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegionalRecurrenceValidationResponse(); }
}

// @Route("/medical-review/form/regional-recurrence/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - RegionalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class RegionalRecurrenceGetSingleByMedicalReviewIdAndRepeat implements IReturn<RegionalRecurrenceSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<RegionalRecurrenceGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegionalRecurrenceGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RegionalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/regional-recurrence/single/update", "POST")
// @Api(Description="Form - RegionalRecurrence")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.RegionalRecurrenceSingleResponse)", StatusCode=500)
// @DataContract
export class RegionalRecurrencePostUpdateById implements IReturn<RegionalRecurrenceSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<RegionalRecurrencePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegionalRecurrencePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegionalRecurrenceSingleResponse(); }
}

// @Route("/medical-review/form/second-primary-invasive-cancer/single/id/{Id}", "GET")
// @Api(Description="Form - SecondPrimaryInvasiveCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryInvasiveCancerGetSingleById implements IReturn<SecondPrimaryInvasiveCancerSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryInvasiveCancerGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryInvasiveCancerSingleResponse(); }
}

// @Route("/medical-review/form/second-primary-invasive-cancer/collection", "GET")
// @Api(Description="Form - SecondPrimaryInvasiveCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerCollectionResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryInvasiveCancerGetCollection implements IReturn<SecondPrimaryInvasiveCancerCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryInvasiveCancerGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryInvasiveCancerCollectionResponse(); }
}

// @Route("/medical-review/form/second-primary-invasive-cancer/create", "POST")
// @Api(Description="Form - SecondPrimaryInvasiveCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryInvasiveCancerPostCreate implements IReturn<SecondPrimaryInvasiveCancerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SecondPrimaryInvasiveCancer;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryInvasiveCancerPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryInvasiveCancerSingleResponse(); }
}

// @Route("/medical-review/form/second-primary-invasive-cancer/save", "POST")
// @Api(Description="Form - SecondPrimaryInvasiveCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryInvasiveCancerPostSave implements IReturn<SecondPrimaryInvasiveCancerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SecondPrimaryInvasiveCancer;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryInvasiveCancerPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryInvasiveCancerSingleResponse(); }
}

// @Route("/medical-review/form/second-primary-invasive-cancer/validate", "POST")
// @Api(Description="Form - SecondPrimaryInvasiveCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerValidationResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryInvasiveCancerPostValidate implements IReturn<SecondPrimaryInvasiveCancerValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SecondPrimaryInvasiveCancer;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryInvasiveCancerPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryInvasiveCancerValidationResponse(); }
}

// @Route("/medical-review/form/second-primary-invasive-cancer/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SecondPrimaryInvasiveCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryInvasiveCancerGetSingleByMedicalReviewIdAndRepeat implements IReturn<SecondPrimaryInvasiveCancerSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryInvasiveCancerGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SecondPrimaryInvasiveCancerSingleResponse(); }
}

// @Route("/medical-review/form/second-primary-invasive-cancer/single/update", "POST")
// @Api(Description="Form - SecondPrimaryInvasiveCancer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SecondPrimaryInvasiveCancerSingleResponse)", StatusCode=500)
// @DataContract
export class SecondPrimaryInvasiveCancerPostUpdateById implements IReturn<SecondPrimaryInvasiveCancerSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SecondPrimaryInvasiveCancerPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecondPrimaryInvasiveCancerPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecondPrimaryInvasiveCancerSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetSingleById implements IReturn<SeriousAdverseEventSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/collection", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetCollection implements IReturn<SeriousAdverseEventCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventCollectionResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/create", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostCreate implements IReturn<SeriousAdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/save", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostSave implements IReturn<SeriousAdverseEventSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/validate", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostValidate implements IReturn<SeriousAdverseEventValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SeriousAdverseEvent;

    public constructor(init?: Partial<SeriousAdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventValidationResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventGetSingleByMedicalReviewIdAndRepeat implements IReturn<SeriousAdverseEventSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SeriousAdverseEventGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/serious-adverse-event/single/update", "POST")
// @Api(Description="Form - SeriousAdverseEvent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SeriousAdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class SeriousAdverseEventPostUpdateById implements IReturn<SeriousAdverseEventSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SeriousAdverseEventPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SeriousAdverseEventPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeriousAdverseEventSingleResponse(); }
}

// @Route("/medical-review/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/collection", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/medical-review/form/source-document/create", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostCreate implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/save", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/validate", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/medical-review/form/source-document/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByMedicalReviewIdAndRepeat implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/single/update", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostUpdateById implements IReturn<SourceDocumentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SourceDocumentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/stratification-factor/single/id/{Id}", "GET")
// @Api(Description="Form - StratificationFactor")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=500)
// @DataContract
export class StratificationFactorGetSingleById implements IReturn<StratificationFactorSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<StratificationFactorGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StratificationFactorGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StratificationFactorSingleResponse(); }
}

// @Route("/medical-review/form/stratification-factor/collection", "GET")
// @Api(Description="Form - StratificationFactor")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.StratificationFactorCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.StratificationFactorCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.StratificationFactorCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.StratificationFactorCollectionResponse)", StatusCode=500)
// @DataContract
export class StratificationFactorGetCollection implements IReturn<StratificationFactorCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<StratificationFactorGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StratificationFactorGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StratificationFactorCollectionResponse(); }
}

// @Route("/medical-review/form/stratification-factor/create", "POST")
// @Api(Description="Form - StratificationFactor")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=500)
// @DataContract
export class StratificationFactorPostCreate implements IReturn<StratificationFactorSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: StratificationFactor;

    public constructor(init?: Partial<StratificationFactorPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StratificationFactorPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StratificationFactorSingleResponse(); }
}

// @Route("/medical-review/form/stratification-factor/save", "POST")
// @Api(Description="Form - StratificationFactor")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=500)
// @DataContract
export class StratificationFactorPostSave implements IReturn<StratificationFactorSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: StratificationFactor;

    public constructor(init?: Partial<StratificationFactorPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StratificationFactorPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StratificationFactorSingleResponse(); }
}

// @Route("/medical-review/form/stratification-factor/validate", "POST")
// @Api(Description="Form - StratificationFactor")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.StratificationFactorValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.StratificationFactorValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.StratificationFactorValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.StratificationFactorValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.StratificationFactorValidationResponse)", StatusCode=500)
// @DataContract
export class StratificationFactorPostValidate implements IReturn<StratificationFactorValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: StratificationFactor;

    public constructor(init?: Partial<StratificationFactorPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StratificationFactorPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StratificationFactorValidationResponse(); }
}

// @Route("/medical-review/form/stratification-factor/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - StratificationFactor")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=500)
// @DataContract
export class StratificationFactorGetSingleByMedicalReviewIdAndRepeat implements IReturn<StratificationFactorSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<StratificationFactorGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StratificationFactorGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StratificationFactorSingleResponse(); }
}

// @Route("/medical-review/form/stratification-factor/single/update", "POST")
// @Api(Description="Form - StratificationFactor")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.StratificationFactorSingleResponse)", StatusCode=500)
// @DataContract
export class StratificationFactorPostUpdateById implements IReturn<StratificationFactorSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<StratificationFactorPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StratificationFactorPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StratificationFactorSingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity/single/id/{Id}", "GET")
// @Api(Description="Form - TreatmentToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityGetSingleById implements IReturn<TreatmentToxicitySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentToxicityGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicitySingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity/collection", "GET")
// @Api(Description="Form - TreatmentToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityGetCollection implements IReturn<TreatmentToxicityCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TreatmentToxicityGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicityCollectionResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity/create", "POST")
// @Api(Description="Form - TreatmentToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityPostCreate implements IReturn<TreatmentToxicitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TreatmentToxicity;

    public constructor(init?: Partial<TreatmentToxicityPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicitySingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity/save", "POST")
// @Api(Description="Form - TreatmentToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityPostSave implements IReturn<TreatmentToxicitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TreatmentToxicity;

    public constructor(init?: Partial<TreatmentToxicityPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentToxicitySingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity/validate", "POST")
// @Api(Description="Form - TreatmentToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicityValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityValidationResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityPostValidate implements IReturn<TreatmentToxicityValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TreatmentToxicity;

    public constructor(init?: Partial<TreatmentToxicityPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentToxicityValidationResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TreatmentToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityGetSingleByMedicalReviewIdAndRepeat implements IReturn<TreatmentToxicitySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TreatmentToxicityGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicitySingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity/single/update", "POST")
// @Api(Description="Form - TreatmentToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityPostUpdateById implements IReturn<TreatmentToxicitySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TreatmentToxicityPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentToxicitySingleResponse(); }
}

// @Route("/medical-review/form/adjuvant-ipsilateral-breast-radiation-therapy/single/id/{Id}", "GET")
// @Api(Description="Form - AdjuvantIpsilateralBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyGetSingleById implements IReturn<AdjuvantIpsilateralBreastRadiationTherapySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdjuvantIpsilateralBreastRadiationTherapyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdjuvantIpsilateralBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/adjuvant-ipsilateral-breast-radiation-therapy/collection", "GET")
// @Api(Description="Form - AdjuvantIpsilateralBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse)", StatusCode=500)
// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyGetCollection implements IReturn<AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdjuvantIpsilateralBreastRadiationTherapyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdjuvantIpsilateralBreastRadiationTherapyCollectionResponse(); }
}

// @Route("/medical-review/form/adjuvant-ipsilateral-breast-radiation-therapy/create", "POST")
// @Api(Description="Form - AdjuvantIpsilateralBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyPostCreate implements IReturn<AdjuvantIpsilateralBreastRadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdjuvantIpsilateralBreastRadiationTherapy;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdjuvantIpsilateralBreastRadiationTherapyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdjuvantIpsilateralBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/adjuvant-ipsilateral-breast-radiation-therapy/save", "POST")
// @Api(Description="Form - AdjuvantIpsilateralBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyPostSave implements IReturn<AdjuvantIpsilateralBreastRadiationTherapySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdjuvantIpsilateralBreastRadiationTherapy;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdjuvantIpsilateralBreastRadiationTherapyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdjuvantIpsilateralBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/adjuvant-ipsilateral-breast-radiation-therapy/validate", "POST")
// @Api(Description="Form - AdjuvantIpsilateralBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapyValidationResponse)", StatusCode=500)
// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyPostValidate implements IReturn<AdjuvantIpsilateralBreastRadiationTherapyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AdjuvantIpsilateralBreastRadiationTherapy;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdjuvantIpsilateralBreastRadiationTherapyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdjuvantIpsilateralBreastRadiationTherapyValidationResponse(); }
}

// @Route("/medical-review/form/adjuvant-ipsilateral-breast-radiation-therapy/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AdjuvantIpsilateralBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat implements IReturn<AdjuvantIpsilateralBreastRadiationTherapySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdjuvantIpsilateralBreastRadiationTherapyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdjuvantIpsilateralBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/adjuvant-ipsilateral-breast-radiation-therapy/single/update", "POST")
// @Api(Description="Form - AdjuvantIpsilateralBreastRadiationTherapy")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AdjuvantIpsilateralBreastRadiationTherapySingleResponse)", StatusCode=500)
// @DataContract
export class AdjuvantIpsilateralBreastRadiationTherapyPostUpdateById implements IReturn<AdjuvantIpsilateralBreastRadiationTherapySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AdjuvantIpsilateralBreastRadiationTherapyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdjuvantIpsilateralBreastRadiationTherapyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdjuvantIpsilateralBreastRadiationTherapySingleResponse(); }
}

// @Route("/medical-review/form/baseline-medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - BaselineMedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineMedicalHistoryGetSingleById implements IReturn<BaselineMedicalHistorySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BaselineMedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineMedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineMedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/baseline-medical-history/collection", "GET")
// @Api(Description="Form - BaselineMedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class BaselineMedicalHistoryGetCollection implements IReturn<BaselineMedicalHistoryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BaselineMedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineMedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineMedicalHistoryCollectionResponse(); }
}

// @Route("/medical-review/form/baseline-medical-history/create", "POST")
// @Api(Description="Form - BaselineMedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineMedicalHistoryPostCreate implements IReturn<BaselineMedicalHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BaselineMedicalHistory;

    public constructor(init?: Partial<BaselineMedicalHistoryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineMedicalHistoryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineMedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/baseline-medical-history/save", "POST")
// @Api(Description="Form - BaselineMedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineMedicalHistoryPostSave implements IReturn<BaselineMedicalHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BaselineMedicalHistory;

    public constructor(init?: Partial<BaselineMedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineMedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaselineMedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/baseline-medical-history/validate", "POST")
// @Api(Description="Form - BaselineMedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class BaselineMedicalHistoryPostValidate implements IReturn<BaselineMedicalHistoryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BaselineMedicalHistory;

    public constructor(init?: Partial<BaselineMedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineMedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaselineMedicalHistoryValidationResponse(); }
}

// @Route("/medical-review/form/baseline-medical-history/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BaselineMedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineMedicalHistoryGetSingleByMedicalReviewIdAndRepeat implements IReturn<BaselineMedicalHistorySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BaselineMedicalHistoryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineMedicalHistoryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineMedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/baseline-medical-history/single/update", "POST")
// @Api(Description="Form - BaselineMedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineMedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineMedicalHistoryPostUpdateById implements IReturn<BaselineMedicalHistorySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BaselineMedicalHistoryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineMedicalHistoryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaselineMedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/baseline-toxicity/single/id/{Id}", "GET")
// @Api(Description="Form - BaselineToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineToxicityGetSingleById implements IReturn<BaselineToxicitySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BaselineToxicityGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineToxicityGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineToxicitySingleResponse(); }
}

// @Route("/medical-review/form/baseline-toxicity/collection", "GET")
// @Api(Description="Form - BaselineToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineToxicityCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineToxicityCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineToxicityCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineToxicityCollectionResponse)", StatusCode=500)
// @DataContract
export class BaselineToxicityGetCollection implements IReturn<BaselineToxicityCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BaselineToxicityGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineToxicityGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineToxicityCollectionResponse(); }
}

// @Route("/medical-review/form/baseline-toxicity/create", "POST")
// @Api(Description="Form - BaselineToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineToxicityPostCreate implements IReturn<BaselineToxicitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BaselineToxicity;

    public constructor(init?: Partial<BaselineToxicityPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineToxicityPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineToxicitySingleResponse(); }
}

// @Route("/medical-review/form/baseline-toxicity/save", "POST")
// @Api(Description="Form - BaselineToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineToxicityPostSave implements IReturn<BaselineToxicitySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BaselineToxicity;

    public constructor(init?: Partial<BaselineToxicityPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineToxicityPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaselineToxicitySingleResponse(); }
}

// @Route("/medical-review/form/baseline-toxicity/validate", "POST")
// @Api(Description="Form - BaselineToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineToxicityValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineToxicityValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineToxicityValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineToxicityValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineToxicityValidationResponse)", StatusCode=500)
// @DataContract
export class BaselineToxicityPostValidate implements IReturn<BaselineToxicityValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BaselineToxicity;

    public constructor(init?: Partial<BaselineToxicityPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineToxicityPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaselineToxicityValidationResponse(); }
}

// @Route("/medical-review/form/baseline-toxicity/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BaselineToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineToxicityGetSingleByMedicalReviewIdAndRepeat implements IReturn<BaselineToxicitySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BaselineToxicityGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineToxicityGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BaselineToxicitySingleResponse(); }
}

// @Route("/medical-review/form/baseline-toxicity/single/update", "POST")
// @Api(Description="Form - BaselineToxicity")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BaselineToxicitySingleResponse)", StatusCode=500)
// @DataContract
export class BaselineToxicityPostUpdateById implements IReturn<BaselineToxicitySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BaselineToxicityPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaselineToxicityPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaselineToxicitySingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging-form/single/id/{Id}", "GET")
// @Api(Description="Form - BreastImagingForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingFormGetSingleById implements IReturn<BreastImagingFormSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastImagingFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingFormSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging-form/collection", "GET")
// @Api(Description="Form - BreastImagingForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingFormCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastImagingFormGetCollection implements IReturn<BreastImagingFormCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<BreastImagingFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingFormCollectionResponse(); }
}

// @Route("/medical-review/form/breast-imaging-form/create", "POST")
// @Api(Description="Form - BreastImagingForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingFormPostCreate implements IReturn<BreastImagingFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastImagingForm;

    public constructor(init?: Partial<BreastImagingFormPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingFormPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingFormSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging-form/save", "POST")
// @Api(Description="Form - BreastImagingForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingFormPostSave implements IReturn<BreastImagingFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastImagingForm;

    public constructor(init?: Partial<BreastImagingFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingFormSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging-form/validate", "POST")
// @Api(Description="Form - BreastImagingForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingFormValidationResponse)", StatusCode=500)
// @DataContract
export class BreastImagingFormPostValidate implements IReturn<BreastImagingFormValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: BreastImagingForm;

    public constructor(init?: Partial<BreastImagingFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingFormValidationResponse(); }
}

// @Route("/medical-review/form/breast-imaging-form/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - BreastImagingForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingFormGetSingleByMedicalReviewIdAndRepeat implements IReturn<BreastImagingFormSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<BreastImagingFormGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingFormGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingFormSingleResponse(); }
}

// @Route("/medical-review/form/breast-imaging-form/single/update", "POST")
// @Api(Description="Form - BreastImagingForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.BreastImagingFormSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingFormPostUpdateById implements IReturn<BreastImagingFormSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<BreastImagingFormPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingFormPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingFormSingleResponse(); }
}

// @Route("/medical-review/form/cancer-events/single/id/{Id}", "GET")
// @Api(Description="Form - CancerEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerEventsGetSingleById implements IReturn<CancerEventsSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CancerEventsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerEventsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerEventsSingleResponse(); }
}

// @Route("/medical-review/form/cancer-events/collection", "GET")
// @Api(Description="Form - CancerEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CancerEventsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CancerEventsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CancerEventsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CancerEventsCollectionResponse)", StatusCode=500)
// @DataContract
export class CancerEventsGetCollection implements IReturn<CancerEventsCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CancerEventsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerEventsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerEventsCollectionResponse(); }
}

// @Route("/medical-review/form/cancer-events/create", "POST")
// @Api(Description="Form - CancerEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerEventsPostCreate implements IReturn<CancerEventsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CancerEvents;

    public constructor(init?: Partial<CancerEventsPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerEventsPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerEventsSingleResponse(); }
}

// @Route("/medical-review/form/cancer-events/save", "POST")
// @Api(Description="Form - CancerEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerEventsPostSave implements IReturn<CancerEventsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CancerEvents;

    public constructor(init?: Partial<CancerEventsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerEventsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerEventsSingleResponse(); }
}

// @Route("/medical-review/form/cancer-events/validate", "POST")
// @Api(Description="Form - CancerEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CancerEventsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CancerEventsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CancerEventsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CancerEventsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CancerEventsValidationResponse)", StatusCode=500)
// @DataContract
export class CancerEventsPostValidate implements IReturn<CancerEventsValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CancerEvents;

    public constructor(init?: Partial<CancerEventsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerEventsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerEventsValidationResponse(); }
}

// @Route("/medical-review/form/cancer-events/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - CancerEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerEventsGetSingleByMedicalReviewIdAndRepeat implements IReturn<CancerEventsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CancerEventsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerEventsGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerEventsSingleResponse(); }
}

// @Route("/medical-review/form/cancer-events/single/update", "POST")
// @Api(Description="Form - CancerEvents")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CancerEventsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerEventsPostUpdateById implements IReturn<CancerEventsSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CancerEventsPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerEventsPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerEventsSingleResponse(); }
}

// @Route("/medical-review/form/clinical-assessment-form/single/id/{Id}", "GET")
// @Api(Description="Form - ClinicalAssessmentForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentFormGetSingleById implements IReturn<ClinicalAssessmentFormSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalAssessmentFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentFormSingleResponse(); }
}

// @Route("/medical-review/form/clinical-assessment-form/collection", "GET")
// @Api(Description="Form - ClinicalAssessmentForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentFormGetCollection implements IReturn<ClinicalAssessmentFormCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ClinicalAssessmentFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentFormCollectionResponse(); }
}

// @Route("/medical-review/form/clinical-assessment-form/create", "POST")
// @Api(Description="Form - ClinicalAssessmentForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentFormPostCreate implements IReturn<ClinicalAssessmentFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ClinicalAssessmentForm;

    public constructor(init?: Partial<ClinicalAssessmentFormPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentFormPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentFormSingleResponse(); }
}

// @Route("/medical-review/form/clinical-assessment-form/save", "POST")
// @Api(Description="Form - ClinicalAssessmentForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentFormPostSave implements IReturn<ClinicalAssessmentFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ClinicalAssessmentForm;

    public constructor(init?: Partial<ClinicalAssessmentFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentFormSingleResponse(); }
}

// @Route("/medical-review/form/clinical-assessment-form/validate", "POST")
// @Api(Description="Form - ClinicalAssessmentForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentFormPostValidate implements IReturn<ClinicalAssessmentFormValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ClinicalAssessmentForm;

    public constructor(init?: Partial<ClinicalAssessmentFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentFormValidationResponse(); }
}

// @Route("/medical-review/form/clinical-assessment-form/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ClinicalAssessmentForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentFormGetSingleByMedicalReviewIdAndRepeat implements IReturn<ClinicalAssessmentFormSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentFormGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentFormGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentFormSingleResponse(); }
}

// @Route("/medical-review/form/clinical-assessment-form/single/update", "POST")
// @Api(Description="Form - ClinicalAssessmentForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ClinicalAssessmentFormSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentFormPostUpdateById implements IReturn<ClinicalAssessmentFormSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ClinicalAssessmentFormPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentFormPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentFormSingleResponse(); }
}

// @Route("/medical-review/form/death/single/id/{Id}", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathGetSingleById implements IReturn<DeathSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DeathGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/collection", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=500)
// @DataContract
export class DeathGetCollection implements IReturn<DeathCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DeathGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathCollectionResponse(); }
}

// @Route("/medical-review/form/death/create", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostCreate implements IReturn<DeathSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/save", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostSave implements IReturn<DeathSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/validate", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=500)
// @DataContract
export class DeathPostValidate implements IReturn<DeathValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathValidationResponse(); }
}

// @Route("/medical-review/form/death/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathGetSingleByMedicalReviewIdAndRepeat implements IReturn<DeathSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DeathGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/single/update", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostUpdateById implements IReturn<DeathSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DeathPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy-form/single/id/{Id}", "GET")
// @Api(Description="Form - EndocrineTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyFormGetSingleById implements IReturn<EndocrineTherapyFormSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EndocrineTherapyFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy-form/collection", "GET")
// @Api(Description="Form - EndocrineTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormCollectionResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyFormGetCollection implements IReturn<EndocrineTherapyFormCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<EndocrineTherapyFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapyFormCollectionResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy-form/create", "POST")
// @Api(Description="Form - EndocrineTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyFormPostCreate implements IReturn<EndocrineTherapyFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapyForm;

    public constructor(init?: Partial<EndocrineTherapyFormPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyFormPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy-form/save", "POST")
// @Api(Description="Form - EndocrineTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyFormPostSave implements IReturn<EndocrineTherapyFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapyForm;

    public constructor(init?: Partial<EndocrineTherapyFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy-form/validate", "POST")
// @Api(Description="Form - EndocrineTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormValidationResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyFormPostValidate implements IReturn<EndocrineTherapyFormValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndocrineTherapyForm;

    public constructor(init?: Partial<EndocrineTherapyFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapyFormValidationResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy-form/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - EndocrineTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyFormGetSingleByMedicalReviewIdAndRepeat implements IReturn<EndocrineTherapyFormSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<EndocrineTherapyFormGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyFormGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndocrineTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/endocrine-therapy-form/single/update", "POST")
// @Api(Description="Form - EndocrineTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndocrineTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class EndocrineTherapyFormPostUpdateById implements IReturn<EndocrineTherapyFormSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<EndocrineTherapyFormPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndocrineTherapyFormPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndocrineTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/general-information/single/id/{Id}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleById implements IReturn<GeneralInformationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/collection", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetCollection implements IReturn<GeneralInformationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GeneralInformationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationCollectionResponse(); }
}

// @Route("/medical-review/form/general-information/create", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostCreate implements IReturn<GeneralInformationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/save", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostSave implements IReturn<GeneralInformationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/validate", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostValidate implements IReturn<GeneralInformationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationValidationResponse(); }
}

// @Route("/medical-review/form/general-information/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleByMedicalReviewIdAndRepeat implements IReturn<GeneralInformationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/single/update", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostUpdateById implements IReturn<GeneralInformationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GeneralInformationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/non-protocol-therapy-form/single/id/{Id}", "GET")
// @Api(Description="Form - NonProtocolTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class NonProtocolTherapyFormGetSingleById implements IReturn<NonProtocolTherapyFormSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NonProtocolTherapyFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonProtocolTherapyFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonProtocolTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/non-protocol-therapy-form/collection", "GET")
// @Api(Description="Form - NonProtocolTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormCollectionResponse)", StatusCode=500)
// @DataContract
export class NonProtocolTherapyFormGetCollection implements IReturn<NonProtocolTherapyFormCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NonProtocolTherapyFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonProtocolTherapyFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonProtocolTherapyFormCollectionResponse(); }
}

// @Route("/medical-review/form/non-protocol-therapy-form/create", "POST")
// @Api(Description="Form - NonProtocolTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class NonProtocolTherapyFormPostCreate implements IReturn<NonProtocolTherapyFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonProtocolTherapyForm;

    public constructor(init?: Partial<NonProtocolTherapyFormPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonProtocolTherapyFormPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonProtocolTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/non-protocol-therapy-form/save", "POST")
// @Api(Description="Form - NonProtocolTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class NonProtocolTherapyFormPostSave implements IReturn<NonProtocolTherapyFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonProtocolTherapyForm;

    public constructor(init?: Partial<NonProtocolTherapyFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonProtocolTherapyFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonProtocolTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/non-protocol-therapy-form/validate", "POST")
// @Api(Description="Form - NonProtocolTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormValidationResponse)", StatusCode=500)
// @DataContract
export class NonProtocolTherapyFormPostValidate implements IReturn<NonProtocolTherapyFormValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonProtocolTherapyForm;

    public constructor(init?: Partial<NonProtocolTherapyFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonProtocolTherapyFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonProtocolTherapyFormValidationResponse(); }
}

// @Route("/medical-review/form/non-protocol-therapy-form/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NonProtocolTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class NonProtocolTherapyFormGetSingleByMedicalReviewIdAndRepeat implements IReturn<NonProtocolTherapyFormSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NonProtocolTherapyFormGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonProtocolTherapyFormGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonProtocolTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/non-protocol-therapy-form/single/update", "POST")
// @Api(Description="Form - NonProtocolTherapyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonProtocolTherapyFormSingleResponse)", StatusCode=500)
// @DataContract
export class NonProtocolTherapyFormPostUpdateById implements IReturn<NonProtocolTherapyFormSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NonProtocolTherapyFormPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonProtocolTherapyFormPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonProtocolTherapyFormSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result-form/single/id/{Id}", "GET")
// @Api(Description="Form - ProsignaAssayResultForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultFormGetSingleById implements IReturn<ProsignaAssayResultFormSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProsignaAssayResultFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultFormSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result-form/collection", "GET")
// @Api(Description="Form - ProsignaAssayResultForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultFormGetCollection implements IReturn<ProsignaAssayResultFormCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProsignaAssayResultFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultFormCollectionResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result-form/create", "POST")
// @Api(Description="Form - ProsignaAssayResultForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultFormPostCreate implements IReturn<ProsignaAssayResultFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProsignaAssayResultForm;

    public constructor(init?: Partial<ProsignaAssayResultFormPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultFormPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultFormSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result-form/save", "POST")
// @Api(Description="Form - ProsignaAssayResultForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultFormPostSave implements IReturn<ProsignaAssayResultFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProsignaAssayResultForm;

    public constructor(init?: Partial<ProsignaAssayResultFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultFormSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result-form/validate", "POST")
// @Api(Description="Form - ProsignaAssayResultForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultFormPostValidate implements IReturn<ProsignaAssayResultFormValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProsignaAssayResultForm;

    public constructor(init?: Partial<ProsignaAssayResultFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultFormValidationResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result-form/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ProsignaAssayResultForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultFormGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProsignaAssayResultFormSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultFormGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultFormGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultFormSingleResponse(); }
}

// @Route("/medical-review/form/prosigna-assay-result-form/single/update", "POST")
// @Api(Description="Form - ProsignaAssayResultForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProsignaAssayResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultFormPostUpdateById implements IReturn<ProsignaAssayResultFormSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProsignaAssayResultFormPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultFormPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultFormSingleResponse(); }
}

// @Route("/medical-review/form/surgery-pathology/single/id/{Id}", "GET")
// @Api(Description="Form - SurgeryPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPathologyGetSingleById implements IReturn<SurgeryPathologySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgeryPathologyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPathologyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgeryPathologySingleResponse(); }
}

// @Route("/medical-review/form/surgery-pathology/collection", "GET")
// @Api(Description="Form - SurgeryPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryPathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryPathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryPathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryPathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryPathologyGetCollection implements IReturn<SurgeryPathologyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgeryPathologyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPathologyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgeryPathologyCollectionResponse(); }
}

// @Route("/medical-review/form/surgery-pathology/create", "POST")
// @Api(Description="Form - SurgeryPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPathologyPostCreate implements IReturn<SurgeryPathologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurgeryPathology;

    public constructor(init?: Partial<SurgeryPathologyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPathologyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgeryPathologySingleResponse(); }
}

// @Route("/medical-review/form/surgery-pathology/save", "POST")
// @Api(Description="Form - SurgeryPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPathologyPostSave implements IReturn<SurgeryPathologySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurgeryPathology;

    public constructor(init?: Partial<SurgeryPathologyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPathologyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgeryPathologySingleResponse(); }
}

// @Route("/medical-review/form/surgery-pathology/validate", "POST")
// @Api(Description="Form - SurgeryPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryPathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryPathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryPathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryPathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryPathologyValidationResponse)", StatusCode=500)
// @DataContract
export class SurgeryPathologyPostValidate implements IReturn<SurgeryPathologyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurgeryPathology;

    public constructor(init?: Partial<SurgeryPathologyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPathologyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgeryPathologyValidationResponse(); }
}

// @Route("/medical-review/form/surgery-pathology/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurgeryPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPathologyGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurgeryPathologySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgeryPathologyGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPathologyGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgeryPathologySingleResponse(); }
}

// @Route("/medical-review/form/surgery-pathology/single/update", "POST")
// @Api(Description="Form - SurgeryPathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryPathologySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPathologyPostUpdateById implements IReturn<SurgeryPathologySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgeryPathologyPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgeryPathologyPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgeryPathologySingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity-form/single/id/{Id}", "GET")
// @Api(Description="Form - TreatmentToxicityForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityFormGetSingleById implements IReturn<TreatmentToxicityFormSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentToxicityFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicityFormSingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity-form/collection", "GET")
// @Api(Description="Form - TreatmentToxicityForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityFormGetCollection implements IReturn<TreatmentToxicityFormCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TreatmentToxicityFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicityFormCollectionResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity-form/create", "POST")
// @Api(Description="Form - TreatmentToxicityForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityFormPostCreate implements IReturn<TreatmentToxicityFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TreatmentToxicityForm;

    public constructor(init?: Partial<TreatmentToxicityFormPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityFormPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicityFormSingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity-form/save", "POST")
// @Api(Description="Form - TreatmentToxicityForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityFormPostSave implements IReturn<TreatmentToxicityFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TreatmentToxicityForm;

    public constructor(init?: Partial<TreatmentToxicityFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentToxicityFormSingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity-form/validate", "POST")
// @Api(Description="Form - TreatmentToxicityForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormValidationResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityFormPostValidate implements IReturn<TreatmentToxicityFormValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TreatmentToxicityForm;

    public constructor(init?: Partial<TreatmentToxicityFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentToxicityFormValidationResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity-form/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TreatmentToxicityForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityFormGetSingleByMedicalReviewIdAndRepeat implements IReturn<TreatmentToxicityFormSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TreatmentToxicityFormGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityFormGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentToxicityFormSingleResponse(); }
}

// @Route("/medical-review/form/treatment-toxicity-form/single/update", "POST")
// @Api(Description="Form - TreatmentToxicityForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentToxicityFormSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentToxicityFormPostUpdateById implements IReturn<TreatmentToxicityFormSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TreatmentToxicityFormPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentToxicityFormPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentToxicityFormSingleResponse(); }
}

// @Route("/medical-review/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

// @Route("/medical-review/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupValidationResponse(); }
}

// @Route("/medical-review/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
}

// @Route("/medical-review/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
}

// @Route("/medical-review/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/medical-review/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountryValidationResponse(); }
}

// @Route("/medical-review/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/medical-review/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionValidationResponse(); }
}

// @Route("/medical-review/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/collection", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/medical-review/patient/create", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/save", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/validate", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/medical-review/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: MedicalReviewRequestContext;
    public permissions: MedicalReviewPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/id/{Id}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleById implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/medical-review-definition/collection", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetCollection implements IReturn<MedicalReviewDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionCollectionResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/code/{Code}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleByCode implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/query-recipient/single/id/{Id}", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetSingleById implements IReturn<QueryRecipientSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryRecipientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientSingleResponse(); }
}

// @Route("/medical-review/query-recipient/collection", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetCollection implements IReturn<QueryRecipientCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryRecipientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientCollectionResponse(); }
}

// @Route("/medical-review/reviewer/single/id/{Id}", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetSingleById implements IReturn<ReviewerSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ReviewerGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/collection", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetCollection implements IReturn<ReviewerCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ReviewerGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerCollectionResponse(); }
}

// @Route("/medical-review/reviewer/create", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostCreate implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/save", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostSave implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/validate", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostValidate implements IReturn<ReviewerValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/id/{Id}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleById implements IReturn<MedicalReviewSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollection implements IReturn<MedicalReviewCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreate implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/save", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostSave implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/validate", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostValidate implements IReturn<MedicalReviewValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleByPatientIdAndRepeat implements IReturn<MedicalReviewSingleResponse>, IMedicalReviewGetSingleByPatientIdAndRepeat
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleByPatientIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollectionByPatientId implements IReturn<MedicalReviewCollectionResponse>, IMedicalReviewGetCollectionByPatientId
{
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<MedicalReviewGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreateReview implements IReturn<MedicalReviewCreationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=2)
    public options: MedicalReviewCreationOptions;

    public constructor(init?: Partial<MedicalReviewPostCreateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/update-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostUpdateReview implements IReturn<MedicalReviewCreationSingleResponse>, IMedicalReviewPostUpdateReview
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public options: MedicalReviewUpdateOptions;

    public constructor(init?: Partial<MedicalReviewPostUpdateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostUpdateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/validate-forms", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostFormValidationCollectionById implements IReturn<MedicalReviewValidationCollectionResponse>, IMedicalReviewPostFormValidationCollectionById
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationCollectionResponse(); }
}

// @Route("/medical-review/medical-review/{MedicalReviewId}/next-form", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewNextFormResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetNextFormByIds implements IReturn<MedicalReviewNextFormResponse>, IMedicalReviewGetNextFormByIds
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=1)
    public currentFormDefinitionId?: number;

    // @DataMember(Order=1)
    public currentFormRepeat?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public statuses: MedicalReviewStatus[];

    public constructor(init?: Partial<MedicalReviewGetNextFormByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetNextFormByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewNextFormResponse(); }
}

// @Route("/medical-review/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

// @Route("/medical-review/query/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByMedicalReviewId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByMedicalReviewId
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryGetCollectionByMedicalReviewId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByMedicalReviewId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

